import styled from "styled-components";

export const ClientsContainer = styled.div`
  padding: 15vh;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1280px) and (max-width: 1366px) {
    padding: 5vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1367px) and (max-width: 1440px) {
    padding: 5vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1441px) and (max-width: 1536px) {
    padding: 5vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1499px) and (max-width: 1550px) {
    padding: 4vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1551px) and (max-width: 1600px) {
    padding: 5vh;
    width: 75%;
    margin: 0 auto;
  }

  @media (min-width: 1601px) and (max-width: 1699px) {
    padding: 5vh;
    width: 75%;
    margin: 0 auto;
  }

  @media (min-width: 1700px) and (max-width: 1920px) {
    padding: 5vh;
    width: 75%;
    margin: 0 auto;
  }

  @media (min-width: 1921px) and (max-width: 2560px) {
    padding: 5vh;
    width: 80%;
    margin: 0 auto;
  }
`;

export const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
