import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault'
import resImg1 from '../../assets/images/ideas/ideas_research.png'
import resImg2 from '../../assets/images/ideas/ideas_research_energy-efficiency.png'
import resImg3 from '../../assets/images/ideas/ideas_research_smart_cities.png'
import resImg4 from '../../assets/images/ideas/ideas_research_CAAD.png'
import resImg5 from '../../assets/images/ideas/ideas_research_indoor.png'
import resImg6 from '../../assets/images/ideas/ideas_research_climate-responsive.png'
import resImg7 from '../../assets/images/ideas/ideas_research_climate-change.png'
import resImg8 from '../../assets/images/ideas/ideas_research_refurbishment.png'
import PageLoading from '../../components/utils/PageLoading'


function Research() {
  const subMenuItems = [
    { title: "publications", href: "/ideas/publications" },
    { title: "news", href: "/ideas/news" },
    { title: "research", href: "/ideas/research" },
    { title: "innovation", href: "/ideas/innovation" },
    { title: "explore", href: "/ideas/explore" }
  ];

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }
  return (
    <LayoutDefault subMenuActiveItem="research" page="ideas" className="overflow-y-scroll" subMenuItems={subMenuItems} menuburgerColor='white-burger'>
      <div className="h-full relative bg-[#7C6776] overflow-hidden pt-14 pb-14">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 place-items-end ">
            <div className="w-full ">
              <img src={resImg1} className="-mt-0 md:-mt-[50px] w-full md:w-[75%] " />
            </div>
          </div>
        </div>
      </div>
      <div className=" sticky inset-0 pointer-events-none z-50 -mt-32 hidden md:flex justify-end pr-32">
        <div className="md:block hidden w-12 h-32 caret" ></div>
      </div>
      <div className="h-full font-quicksand relative bg-[#81B39B] overflow-hidden pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px] ">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={resImg2} className="w-full md:w-[70%]" />
            </div>

            <div className="w-full md:w-[120%] md:text-right text-left mt-[30px] md:mt-0">
              <h2 className="text-white font-semibold text-[20px] md:text-4xl md:mb-5 mb-0">energy efficient, low/zero carbon,<br></br> and green buildings/communities; </h2>

            </div>
          </div>
        </div>
      </div>
      <div className="h-full font-quicksand relative bg-[#282560] overflow-hidden pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={resImg3} className="w-full md:w-[80%]" />
            </div>
            <div className="w-full md:text-right text-left mt-[30px] md:mt-0">
              <h2 className="text-white font-semibold md:text-4xl text-[20px] mb-0 md:mb-5">smart cities <br></br> and resilient environments;</h2>

            </div>
          </div>
        </div>
      </div>
      <div className="h-full font-quicksand relative bg-[#CE8FB1] overflow-hidden pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40  place-items-end">
            <div className="w-full">
              <img src={resImg4} className="w-full md:w-[60%]" />
            </div>
            <div className="w-full md:text-right text-left mt-[30px] md:mt-0">
              <h2 className="text-white md:text-4xl font-semibold text-[20px] mb-0 md:mb-5">computer-aided design and  <br></br>simulation;</h2>

            </div>
          </div>
        </div>
      </div>
      <div className="h-full font-quicksand relative bg-[#959AC6] overflow-hidden pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2  pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={resImg5} className="w-full md:w-[52%]" />
            </div>
            <div className="w-full md:w-[150%] md:text-right text-left mt-[30px] md:mt-0">
              <h2 className="text-white md:text-4xl font-semibold text-[20px] mb-0 md:mb-5">indoor environmental quality <br></br> <span className="font-light italic">(acoustic, visual, thermal, moisture, air quality);</span></h2>

            </div>
          </div>
        </div>
      </div>
      <div className="h-full font-quicksand relative bg-[#FAC88F] overflow-hidden pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={resImg6} className="w-full md:w-[67%]" />
            </div>
            <div className="w-full md:w-[120%] md:text-right text-left mt-[30px] md:mt-0">
              <h2 className="text-white md:text-4xl font-semibold text-[20px] mb-0 md:mb-5">climate-responsive architectural <br></br> design;</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full font-quicksand relative bg-[#726659] overflow-hidden pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={resImg7} className="w-full md:w-[70%]" />
            </div>
            <div className="w-full md:w-[140%]  md:text-right text-left mt-[30px] md:mt-0">
              <h2 className="text-white md:text-4xl font-semibold text-[20px] mb-0 md:mb-5">climate change mitigation and <br></br> adaptation in urban environments;</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full font-quicksand relative bg-[#B197C6] overflow-hidden pt-14 pb-[100px] md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={resImg8} className="w-full md:w-[60%]" />
            </div>
            <div className="w-full md:w-[120%] md:float-right md:text-right text-left mt-[30px] md:mt-0">
              <h2 className="text-white md:text-4xl font-semibold text-[20px] mb-0 md:mb-5">retrofit and refurbishment of <br></br> existing  buildings;</h2>

            </div>
          </div>
        </div>
      </div>
    </LayoutDefault >
  )
}

export default Research