import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault'
import circleIcon from '../../assets/images/circle-icon.png'
import circle from '../../assets/images/circle.png'
import PageLoading from '../../components/utils/PageLoading';
import { ColOne, ColTwo, ImageContainer, Point, ProfileContainer, ProfileFlexContainer } from './Profile.styles';

import mobileImg from '../../assets/profile/mobile.svg'
import desktopSM from '../../assets/profile/desktop-sm.svg'
import desktopMD from '../../assets/profile/desktop-md.svg'
import desktop from '../../assets/profile/desktop.svg'
import desktopLG from '../../assets/profile/desktop-lg.svg'

function Profile() {
  const subMenuItems = [
    { title: "profile", href: "/about/profile" },
    { title: "people", href: "/about/people" },
    { title: "clients", href: "/about/clients" },
  ];
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }


  return (
    <LayoutDefault
      color="fill-black"
      subMenuActiveItem="profile"
      page="about"
      className=" bg-no-repeat overflow-x-auto md:overflow-y-hidden"
      subMenuItems={subMenuItems}
      menuburgerColor='dark-burger'
    >
      <ImageContainer className="block md:none h-screen pointer-events-none">
        <img src={mobileImg} className='mobile w-full' alt="" />
        <img src={desktopSM} className='desktop-sm w-[95%]' alt="" />
        <img src={desktopMD} className='desktop-md' alt="" />
        <img src={desktop} className='desktop' alt="" />
        <img src={desktopLG} className='desktop-lg' alt="" />
      </ImageContainer>
    </LayoutDefault >
  )
}

export default Profile
