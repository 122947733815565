import { useSpring, animated, easings } from "@react-spring/web";
import logoNoCircle from '../assets/logo-nocircle.png'

export default function Loading({ play = false }) {
  const animDot = useSpring({
    from: { width: "10px", height: "10px" },
    to: { width: "65px", height: "65px" },
    config: {
      duration: 2000,
      easing: easings.easeOutBounce,
    },
  });
  const animScreen = useSpring({
    from: { height: "100%" },
    to: { height: "0" },
    delay: 2000,
    config: {
      duration: 750,
      easing: easings.easeInSine,
    },
  });

  const animMenu = useSpring({
    immediate: !play,
    from: { backgroundColor: "rgba(255,255,255,1)" },
    to: { backgroundColor: "rgba(255,255,255,0)" },
    delay: 2000,
    config: {
      duration: 1000,
    },
  });

  return (
    <div className="hidden h-full md:block relative inset-0 z-50 pointer-events-none">
      <div className="flex flex-col items-end justify-end">
        <div className="grow overflow-hidden flex items-end">
          {play && (
            <animated.div
              style={play ? animScreen : {}}
              className="h-full bg-white"
            ></animated.div>
          )}
        </div>
        <animated.div
          style={play ? animMenu : {}}
          className="h-full relative flex flex-col bg-transparent"
        >
          <div className="flex grow items-end justify-end">
            <a
              href="/"
              className="w-[14rem] flex items-center relative pointer-events-auto"
            >
              <div className="w-full inset-0">
                <img src={logoNoCircle} />
              </div>
              <div className="absolute inset-0" style={{ "top": " calc(0% + -5px)", 'left': 'calc(30% - 15px)' }}>
                <div className="w-[70px] h-[70px] flex items-center justify-center">
                  <animated.div
                    style={animDot}
                    className="bg-black rounded-full"
                  ></animated.div>
                </div>
              </div>
            </a>
          </div>
        </animated.div>
      </div>
    </div>
  );
}
