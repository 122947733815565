import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault'
import PageLoading from '../../components/utils/PageLoading';

function About() {
  const [loading, setLoading] = useState(true)
  const subMenuItems = [
    { title: "profile", href: "/about/profile" },
    { title: "people", href: "/about/people" },
    { title: "clients", href: "/about/clients" },
  ];


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }

  return (
    <LayoutDefault
      page="about"
      className="bg-white p-8 grid grid-cols-1 md:grid-cols-2 gap-20"
      subMenuItems={subMenuItems}
      color="fill-black"
    >
      <div className="space-y-10 md:space-y-16 flex justify-center flex-col">
        <p className="font-bold pr-0 md:pr-32">
          <span className="font-condition text-5xl">W</span>e are an
          architectural design office within a trans-disciplinary outlook,
          based in Tirana and Pristina since 2007. We believe our office
          demonstrates its commitment to building performance in a creative
          way, enhancing the sense of environment, human comfort and ecology.
        </p>
        <p className="pl-0 md:pl-20">
          <span className="text-5xl">&#9679;</span>ur work varies in different
          typologies and sizes of projects including residences, hotel
          complexes, commercial, agro-tourism, industry, etc.
        </p>
        <p className="">
          <span className="text-5xl">&#9679;</span>ur hallmark is the offer to
          customers a dedicated premium quality service, where the customer is
          constantly involved in the process. Professionally tailored this
          premise enables security, clarity, satisfaction, and mutual loyalty.
        </p>
      </div>
      <div className="space-y-10 md:space-y-16 flex justify-center flex-col">
        <p>
          <span className="text-5xl">&#9679;</span>ur team characterized by an
          in-depth professional and scientific expertise regarding design,
          based on sustainability principles. Our team is focused and trained
          with the latest technology including BIM and Computational
          Simulation in Energy Efficiency, Urban Microclimate, Daylighting,
          Acoustics.
        </p>
        <p className="font-bold">
          <span className="text-5xl">&#9679;</span>ur highly research-based
          design and collaborative approach between different disciplines, is
          a fundamental driving force of the design process, ensuring quality
          and efficiency.
        </p>
      </div>

    </LayoutDefault>
  )
}

export default About