import React, { useEffect, useRef, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import Slider from 'react-slick';
import { Link, useParams } from 'react-router-dom';
import rightArrow from '../../assets/icons/right.svg'
import leftArrow from '../../assets/icons/left.svg'
import PageLoading from '../../components/utils/PageLoading'
import axe from '../../config/axiosClient/axe';
import { SweetAlertDangerModal } from '../../utils/alert';
import { BASE_FILE_URL } from '../../config/urls'

function ProjectsByCategory() {
  const { category } = useParams()
  const slider = useRef(null);
  const [dataState, setDataState] = useState([]);
  const [loading, setLoading] = useState(true)

  const subMenuItems = [
    { title: "commercial", href: "/projects/commercial", slug: "commercial" },
    {
      title: "residential",
      href: "/projects/residential",
      slug: "residential",
    },
    { title: "hotel", href: "/projects/hotel", slug: "hotel" },
    { title: "industrial", href: "/projects/industrial", slug: "industrial" },
    { title: "urban", href: "/projects/urban", slug: "urban" },
    { title: "landscape", href: "/projects/landscape", slug: "landscape" },
  ];


  const settings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    slidesToShow: dataState.length < 2 ? 1 : 2,
    slidesToScroll: 1,
    pauseOnHover: true,
    speed: 1000,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const getProjects = async () => {
    try {
      setLoading(true)
      const response = await axe.get(`/projects/category/${category}`);
      if (response.status !== 200) {
        SweetAlertDangerModal("Something went wrong!");
      }
      if (response.status === 200) {
        setDataState(response.data.data)
        setLoading(false)

      }
    } catch (error) {
      SweetAlertDangerModal("Something went wrong!");
      setLoading(false)
    }
  }


  useEffect(() => {
    getProjects()
  }, [category])

  if (loading) {
    return <PageLoading />
  }
  return (
    <LayoutDefault
      page="projects"
      className="bg-gray-100 overflow-hidden relative"
      subMenuItems={subMenuItems}
      subMenuActiveItem={category}
      menuburgerColor='white-burger'
    >
      <Slider {...settings} ref={slider}>
        {dataState.map((item, index) => (
          <div key={index}>
            <Link
              key={index}
              to={`/project/${item.slug}`}
              className="overflow-hidden"
            >
              <div
                style={{
                  backgroundImage:
                    'url("' + BASE_FILE_URL + '/uploads' + item.featured_image.path + '")',
                }}
                className="h-screen bg-cover bg-center bg-no-repeat flex items-end px-10 md:py-52 py-0"
              >
                <h3 className="font-quicksand font-bold flex flex-col text-white text-left md:pb-18 pb-24 md:pb-0">
                  <span className="text-3xl md:text-7xl">{item.title}</span>
                  <span className="text-lg md:text-2xl font-normal">
                    {item.short_desc}
                  </span>
                </h3>
              </div>
            </Link>
          </div>
        ))
        }
      </Slider >
      {dataState.length > 2 && <div>
        <button
          type="button"
          className="z-40 right-6 top-[50%] -mt-6 w-8 h-8  absolute"
          onClick={() => slider?.current?.slickNext()}
        >
          <img src={rightArrow} className="w-full" />
        </button>
        <button
          type="button"
          className="z-40 left-6 top-[50%] -mt-6 w-8 h-8 absolute"
          onClick={() => slider?.current?.slickPrev()}
        >
          <img src={leftArrow} className="w-full" />
        </button>
      </div>}
    </LayoutDefault >
  )
}

export default ProjectsByCategory