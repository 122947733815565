import React from "react";

export default function Footer() {
  return (
    // <footer className="fixed bg-white border-t-[1px] bottom-0 w-full text-sm text-center md:pt-0  md:border-t-[0px] ">
    //   <p className="py-3">&copy; pOint arkitekture</p>
    // </footer>
    <footer className="bg-white border-t-[1px] w-full text-sm text-center md:pt-0  md:border-t-[0px] ">
      <p className="py-3">&copy; pOint arkitekture</p>
    </footer>
  );
}
