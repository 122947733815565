import React from 'react'

function Person({ image, name, role }) {
  return (
    <div className="group relative w-full">
      <img className="object-cover" src={image} />
      <div className=" w-full absolute flex flex-col inset-0 pb-1 pr-3 items-end justify-end invisible group-hover:animate-pulse group-hover:visible bg-[rgba(0,0,0,0.6)] h-full">
        <p className="font-semibold text-white text-[16px] md:text-[20px] leading-[16px] w-full block text-right">
          {name}
        </p>
        <span className="text-[14px] md:text-[16px] font-semibold text-white w-full block text-right">
          {role}
        </span>
      </div>
    </div>
  )
}

export default Person