import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';


import accouImg1Mobile from '../../assets/daylight/mobile/section-1/mobile.svg'
import sec1DesktopLg from '../../assets/daylight/mobile/section-1/desktop-lg.svg'
import sec1Desktop from '../../assets/daylight/mobile/section-1/desktop.svg'
import sec1DesktopMd from '../../assets/daylight/mobile/section-1/desktop-md.svg'
import sec1DesktopSm from '../../assets/daylight/mobile/section-1/desktop-sm.svg'

import accouImg2Mobile from '../../assets/daylight/mobile/section-2/mobile.svg'
import sec2DesktopLg from '../../assets/daylight/mobile/section-2/desktop-lg.svg'
import sec2Desktop from '../../assets/daylight/mobile/section-2/desktop.svg'
import sec2DesktopMd from '../../assets/daylight/mobile/section-2/desktop-md.svg'
import sec2DesktopSm from '../../assets/daylight/mobile/section-2/desktop-sm.svg'

import accouImg3Mobile from '../../assets/daylight/mobile/section-3/mobile.svg'
import sec3DesktopLg from '../../assets/daylight/mobile/section-3/desktop-lg.svg'
import sec3Desktop from '../../assets/daylight/mobile/section-3/desktop.svg'
import sec3DesktopMd from '../../assets/daylight/mobile/section-3/desktop-md.svg'
import sec3DesktopSm from '../../assets/daylight/mobile/section-1/desktop-sm.svg'
import PageLoading from '../../components/utils/PageLoading';
import { ImageContainer, InfoContainerSectionOne, InfoContainerSectionTwo, InitialContainer, SectionOne, SectionTwo } from './Daylight.styles';

function Daylight() {
  const subMenuItems = [
    { title: "sustainability", href: "/expertise/sustainability" },
    { title: "energy efficiency", href: "/expertise/energy-efficiency" },
    { title: "daylight", href: "/expertise/daylight" },
    { title: "acoustics", href: "/expertise/acoustics" },
    {
      title: "computational simulation",
      href: "/expertise/computational-simulation",
    },
  ];
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }


  return (
    <LayoutDefault subMenuActiveItem="daylight" page="expertise" className='overflow-y-auto' subMenuItems={subMenuItems} menuburgerColor='white-burger'>

      <div className='bg-[#17375E]'>
        <InitialContainer className='bg-[#17375E]'>
          <SectionOne>
            <ImageContainer>
              <img src={accouImg1Mobile} className='mobile' alt="" width={376} />
              <img src={sec1DesktopSm} className='desktop-sm' alt="" width={576} />
              <img src={sec1DesktopMd} className='desktop-md' alt="" width={620} />
              <img src={sec1Desktop} className='desktop' alt="" width={833} />
              <img src={sec1DesktopLg} className='desktop-lg' alt="" width={1097} />
            </ImageContainer>
            <InfoContainerSectionOne >
              <h2>
                The use of <span>daylight</span> can have a profound impact on the well-being and ...
              </h2>
            </InfoContainerSectionOne>
          </SectionOne>
        </InitialContainer>

        <div className="sticky inset-0 pointer-events-none z-50 -mt-[5%] hidden md:flex justify-end pr-32">
          <div className="md:block hidden w-12 h-24 caret3 bg-[#F9EC31]"></div>
        </div>


        <InitialContainer className='bg-[#17375E]'>
          <SectionTwo>
            <ImageContainer className='daylight-img-2'>
              <img src={accouImg2Mobile} className='mobile' alt="" width={340} />
              <img src={sec2DesktopSm} className='desktop-sm' alt="" width={448} />
              <img src={sec2DesktopMd} className='desktop-md' alt="" width={514} />
              <img src={sec2Desktop} className='desktop' alt="" width={711} />
              <img src={sec2DesktopLg} className='desktop-lg' alt="" width={1011} />
            </ImageContainer>
            <InfoContainerSectionTwo>
              <p>
                <div /><span>productivity</span> of occupants in a building. Exposure to daylight has been linked to improved mood, increased alertness, reduced stress levels, and improved sleep quality. Additionally, it is a key component of sustainable building design, as it helps reduce energy consumption by providing natural light to illuminate <span>indoor spaces</span>.
              </p>
              <p>
                <div /> pOint arkitekture strongly focuses on daylighting and has integrated this design element into their projects. pOint arkitekture's approach to daylighting involves analyzing the site's solar <span>exposure</span>, designing the building's <span>orientation</span> and <span>envelope</span> to maximize natural light, and using design strategies on transparency and shading systems.
              </p>

            </InfoContainerSectionTwo>
          </SectionTwo>
        </InitialContainer>

        <InitialContainer className='bg-[#17375E]'>
          <SectionTwo>
            <ImageContainer>
              <img src={accouImg3Mobile} className='mobile' alt="" width={414} />
              <img src={sec3DesktopSm} className='desktop-sm' alt="" width={506} />
              <img src={sec3DesktopMd} className='desktop-md' alt="" width={582} />
              <img src={sec3Desktop} className='desktop' alt="" width={798} />
              <img src={sec3DesktopLg} className='desktop-lg' alt="" width={1254} />
            </ImageContainer>
            <InfoContainerSectionTwo className='content'>
              <p>
                <div />The office's approach to daylighting involves using advanced modelling software to analyze the site's solar exposure and designing the building's envelope to maximize natural light while minimizing heat gain. The office has implemented daylighting strategies in many of its projects.
              </p>
            </InfoContainerSectionTwo>
          </SectionTwo>
        </InitialContainer>
      </div>
    </LayoutDefault >
  );
}

export default Daylight