import styled from "styled-components";

// 1920×1080 //!OK
// 360×800
// 1366×768 //!OK
// 1536×864 //!OK
// 390×844
// 414×896
// 412×915
// 393×873
// 1280×720 //!OK
// 360×780
// 360×640
// 1440×900 //!OK
// 375×812
// 1600×900 //!OK
// 2560×1440
// 810×1080

export const PeopleContainer = styled.div`
  padding: 15vh;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1280px) and (max-width: 1366px) {
    padding: 5vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1367px) and (max-width: 1440px) {
    padding: 5vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1441px) and (max-width: 1536px) {
    padding: 5vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1499px) and (max-width: 1550px) {
    padding: 4vh;
    width: 70%;
    margin: 0 auto;
  }

  @media (min-width: 1551px) and (max-width: 1600px) {
    padding: 5vh;
    width: 75%;
    margin: 0 auto;
  }

  @media (min-width: 1601px) and (max-width: 1699px) {
    padding: 5vh;
    width: 75%;
    margin: 0 auto;
  }

  @media (min-width: 1700px) and (max-width: 1920px) {
    padding: 5vh;
    width: 75%;
    margin: 0 auto;
  }

  @media (min-width: 1921px) and (max-width: 2560px) {
    padding: 5vh;
    width: 80%;
    margin: 0 auto;
  }
`;

export const PeopleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 100px;

  @media (min-width: 1300px) and (max-width: 1550px) {
    grid-column-gap: 50px;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const PeopleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "quicksand";
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 600;
  grid-column: 1/3;
  span {
    margin: 0 3px;
    width: 30px;
    height: 30px;
    background: #000;
    display: inline-block;
    border-radius: 50%;
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;
    grid-column: 4/6;
    span {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (min-width: 1366px) {
    font-size: 30px;
    grid-column: 4/6;
    span {
      width: 40px;
      height: 40px;
    }
  }

  @media screen and (min-width: 1920px) {
    font-size: 40px;
    grid-column: 4/6;
    span {
      margin: 0 3px;
      width: 50px;
      height: 50px;
      background: #000;
      display: inline-block;
      border-radius: 50%;
    }
  }

  @media screen and (min-width: 2730px) {
    font-size: 60px;
    grid-column: 4/6;
    span {
      margin: 0 3px;
      width: 70px;
      height: 70px;
      background: #000;
      display: inline-block;
      border-radius: 50%;
    }
  }
`;
