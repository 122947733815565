import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import About from "./pages/About/About";
import Profile from "./pages/About/Profile";
import People from "./pages/About/People";
import Clients from "./pages/About/Clients";
import Projects from "./pages/Projects/Projects";
import SingleProject from "./pages/Projects/SingleProject";
import Contact from "./pages/Contact";
import Sustainability from "./pages/Expertise/Sustainability";
import Energy from "./pages/Expertise/Energy";
import Daylight from "./pages/Expertise/Daylight";
import Acoustics from "./pages/Expertise/Acoustics";
import ComputalSimulation from "./pages/Expertise/ComputalSimulation";
import Publications from "./pages/Ideas/Publications";
import News from "./pages/Ideas/News";
import Research from "./pages/Ideas/Research";
import Innovation from "./pages/Ideas/Innovation";
import Explore from "./pages/Ideas/Explore";
import ProjectsByCategory from "./pages/Projects/ProjectsByCategory";
import "./assets/fonts/condition-italic-webfont.ttf";
import "./assets/fonts/condition-regular-webfont.ttf";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/about/profile",
    element: <Profile />,
  },
  {
    path: "/about/people",
    element: <People />,
  },
  {
    path: "/about/clients",
    element: <Clients />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/project/:slug",
    element: <SingleProject />,
  },
  {
    path: "/projects/:category",
    element: <ProjectsByCategory />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/expertise/sustainability",
    element: <Sustainability />,
  },
  {
    path: "/expertise/energy-efficiency",
    element: <Energy />,
  },
  {
    path: "/expertise/daylight",
    element: <Daylight />,
  },
  {
    path: "/expertise/acoustics",
    element: <Acoustics />,
  },
  {
    path: "/expertise/computational-simulation",
    element: <ComputalSimulation />,
  },
  {
    path: "/ideas/publications",
    element: <Publications />,
  },
  {
    path: "/ideas/news",
    element: <News />,
  },
  {
    path: "/ideas/research",
    element: <Research />,
  },
  {
    path: "/ideas/innovation",
    element: <Innovation />,
  },
  {
    path: "/ideas/explore",
    element: <Explore />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
