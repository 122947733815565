import React, { useEffect, useRef, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import Loading from '../../components/Loading';
import PageLoading from '../../components/utils/PageLoading';
import { SweetAlertDangerModal } from '../../utils/alert';
import axe from '../../config/axiosClient/axe';
import { BASE_FILE_URL } from '../../config/urls';
import iconLeft from '../../assets/icons/left.svg'
import iconRight from '../../assets/icons/right.svg'
import { Paragraph } from './SingleProject.styles';

function SingleProject() {
  const slider = useRef(null);
  const { slug } = useParams()
  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(true)
  const subMenuItems = [
    { title: "commercial", href: "/projects/commercial", slug: "commercial" },
    {
      title: "residential",
      href: "/projects/residential",
      slug: "residential",
    },
    { title: "hotel", href: "/projects/hotel", slug: "hotel" },
    { title: "industrial", href: "/projects/industrial", slug: "industrial" },
    { title: "urban", href: "/projects/urban", slug: "urban" },
    { title: "landscape", href: "/projects/landscape", slug: "landscape" },
  ];

  const settings = {
    infinite: true,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "200px",
    pauseOnHover: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerPadding: "100px",
          centerMode: false,

        },
      },
    ],
  };

  const getProject = async () => {
    try {
      setLoading(true)
      const response = await axe.get(`/projects/${slug}`);
      if (response.status !== 200) {
        SweetAlertDangerModal("Something went wrong!");
      }
      if (response.status === 200) {
        setProject(response.data.data)
        setLoading(false)

      }
    } catch (error) {
      SweetAlertDangerModal("Something went wrong!");
      setLoading(false)
    }
  }


  useEffect(() => {
    getProject()
  }, [slug])

  return (
    <LayoutDefault
      subMenuItems={subMenuItems}
      color="fill-black"
      page="projects"
      pageTitle={!loading && project.title}
      ogImage={!loading && project.featured_image.path}
      description={!loading && project.desc.replace(/<[^>]+>/g, "")}
      subMenuActiveItem={!loading && project.category}
      className='overflow-y-auto'
      menuburgerColor='dark-burger'
    >
      {loading === true ? <PageLoading /> :
        <>
          <div className="relative">
            <div
              style={{
                backgroundImage: 'url("' + BASE_FILE_URL + '/uploads' + project.map_image.path + '")',
              }}
              className="bg-cover bg-bottom min-h-[90vh] h-full flex justify-end items-start pt-[140px] pb-10 pl-6 pr-6 md:pr-8 md:pt-24 md:pl-0 md:pb-8 overflow-hidden"
            >
              {/* <div className="absolute bg-white-rgba top-0 right-0 w-full h-full z-0"></div> */}
              <div className="w-full md:w-[550px] text-right z-30">
                <div className="flex w-full md:w-full items-start">
                  <div className="grow text-center">
                    {" "}
                    {project.logo?.path !== undefined && (
                      <img
                        className="md:h-20 h-12 table ms-auto mr-[10%]"
                        src={`${BASE_FILE_URL}/uploads${project.logo.path}`}
                      />
                    )}
                  </div>

                  <div className="">
                    <h3 className="text-2xl md:text-[33px] mb-5 font-bold">
                      {project.title}
                    </h3>
                    <ul className="text-xs font-normal">
                      <li className="text-[14px] leading-[26px]">
                        plot size: {project.plot_size} m<sup>2</sup>
                      </li>
                      <li className="text-[14px] leading-[26px]">
                        built area: {project.built_area} m<sup>2</sup>{" "}
                      </li>
                      {project.capacity !== null && (
                        <li className="text-[14px] leading-[26px]">
                          capacity: {project.capacity} rooms
                        </li>
                      )}

                      <li className="text-[14px] leading-[26px]">
                        budget: {project.budget}€
                      </li>
                      <li className="text-[14px] leading-[26px]">
                        {project.year_location}
                      </li>
                      <li className="text-[14px] leading-[26px]">
                        status: {project.status}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="space-y-8 text-black text-[14px] tracking-wide leading-[34px] pt-5 md:w-[530px] description-p ms-auto">
                  <Paragraph dangerouslySetInnerHTML={{ __html: project.desc }} />
                </div>
              </div>
            </div>
          </div>
          <div className="relative mb-[60px] md:mb-5">
            <Slider {...settings} ref={slider} className="w-full">
              {project.gallery.map((galleryItem, index) => (
                <div key={index} className=" h-auto md:h-full px-2 relative">
                  <div
                    className="bg-cover bg-center bg-no-repeat flex items-end relative"
                  >
                    <img src={`${BASE_FILE_URL}/uploads${galleryItem.path}`} alt="" className='w-full  h-[250px] md:h-auto object-cover md:object-fill' />
                    <p
                      className={`hidden md:block z-50 ${galleryItem.tags.find(tag => tag.name === "right")
                        ? "right-[15px] text-right "
                        : "left-[15px] text-left"
                        } ${galleryItem.tags.find(tag => tag.name === "top")
                          ? "top-[15px] "
                          : "bottom-[15px]"
                        }  absolute text-white text-[12px] leading-[15px] md:text-[14px] md:leading-[16px] font-quicksand`}
                      dangerouslySetInnerHTML={{
                        __html: galleryItem.description,
                      }}
                    ></p>
                    <div
                      className={` font-quicksand text-[13px] leading-[16px] font-[300] absolute invisible md:visible bottom-[15px] text-white w-[85%] ${galleryItem.tags.find(tag => tag.name === "right")
                        ? "right-[15px] text-right "
                        : "left-[215px] text-left"
                        } ${galleryItem.tags.find(tag => tag.name === "top") ? "top-[15px] " : ""
                        } `}
                    />
                  </div>
                </div>
              ))}
            </Slider>
            <div>
              <button
                type="button"
                className="z-40 top-[50%] md:top-[50%] right-6 -mt-6 w-3 h-3 md:w-8 md:h-8 absolute"
                onClick={() => slider?.current?.slickNext()}
              >
                <img src={iconRight} className="w-full" />
              </button>
              <button
                type="button"
                className="z-40 top-[50%] left-6 md:top-[50%] -mt-6 w-3 h-3 md:w-8 md:h-8 absolute"
                onClick={() => slider?.current?.slickPrev()}
              >
                <img src={iconLeft} className="w-full" />
              </button>
            </div>
          </div>
        </>
      }
    </LayoutDefault>
  )
}

export default SingleProject