import React from 'react'
import LayoutDefault from '../../layout/LayoutDefault';

function News() {
  const subMenuItems = [
    { title: "publications", href: "/ideas/publications" },
    { title: "news", href: "/ideas/news" },
    { title: "research", href: "/ideas/research" },
    { title: "innovation", href: "/ideas/innovation" },
    { title: "explore", href: "/ideas/explore" }
  ];
  return (
    <LayoutDefault page="ideas" subMenuActiveItem="news" className="overflow-y-scroll" subMenuItems={subMenuItems} menuburgerColor='white-burger'>
      <div className="h-screen  grid grid-cols-1 items-center bg-[#a89c8e] overflow-hidden">
        <div className="container h-auto  text-center">
          <h1 className="text-4xl text-white">News</h1>
          <h2 className="text-2xl text-white">Coming Soon</h2>
        </div>
      </div>

    </LayoutDefault >
  )
}

export default News