import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import accouImg1Mobile from '../../assets/accoustics/mobile/section-1/mobile.svg'
import sec1DesktopLg from '../../assets/accoustics/mobile/section-1/desktop-lg.svg'
import sec1Desktop from '../../assets/accoustics/mobile/section-1/desktop.svg'
import sec1DesktopMd from '../../assets/accoustics/mobile/section-1/desktop-md.svg'
import sec1DesktopSm from '../../assets/accoustics/mobile/section-1/desktop-sm.svg'
import accouImg2Mobile from '../../assets/accoustics/mobile/section-2/mobile.svg'
import sec2DesktopLg from '../../assets/accoustics/mobile/section-2/desktop-lg.svg'
import sec2Desktop from '../../assets/accoustics/mobile/section-2/desktop.svg'
import sec2DesktopMd from '../../assets/accoustics/mobile/section-2/desktop-md.svg'
import sec2DesktopSm from '../../assets/accoustics/mobile/section-2/desktop-sm.svg'
import { ImageContainer, InfoContainerSectionOne, InitialContainer, SectionOne, InfoContainerSectionTwo, SectionTwo } from './Accoustic.styles';
import PageLoading from '../../components/utils/PageLoading';

function Acoustics() {
  const subMenuItems = [
    { title: "sustainability", href: "/expertise/sustainability" },
    { title: "energy-efficiency", href: "/expertise/energy-efficiency" },
    { title: "daylight", href: "/expertise/daylight" },
    { title: "acoustics", href: "/expertise/acoustics" },
    {
      title: "computational-simulation",
      href: "/expertise/computational-simulation",
    },
  ];
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }


  return (
    <LayoutDefault subMenuActiveItem="acoustics" page="expertise" className='overflow-y-auto' subMenuItems={subMenuItems} menuburgerColor='white-burger'>
      <div className='relative bg-[#A6A4A5]'>
        <InitialContainer className='bg-[#A6A4A5]'>
          <SectionOne>
            <ImageContainer>
              <img src={accouImg1Mobile} className='mobile' alt="" width={310} />
              <img src={sec1DesktopSm} className='desktop-sm' alt="" width={570} />
              <img src={sec1DesktopMd} className='desktop-md' alt="" width={550} />
              <img src={sec1Desktop} className='desktop w-[45%]' alt="" width={650} />
              <img src={sec1DesktopLg} className='desktop-lg' alt="" width={1288} />
            </ImageContainer>
            <InfoContainerSectionOne>
              <h2>
                pOint arkitekture is a leading company in room <span>acoustics</span> design …
              </h2>
            </InfoContainerSectionOne>
          </SectionOne>
        </InitialContainer>

        <div className=" sticky inset-0 pointer-events-none z-50 -mt-[5%] hidden md:flex justify-end pr-32">
          <div className="md:block hidden w-12 h-24 caret5 bg-[#B9484D]" ></div>
        </div>

        <InitialContainer className='bg-[#A6A4A5]'>
          <SectionTwo>
            <ImageContainer>
              <img src={accouImg2Mobile} className='mobile' alt="" width={352} />
              <img src={sec2DesktopSm} className='desktop-sm' alt="" width={502} />
              <img src={sec2DesktopMd} className='desktop-md' alt="" width={557} />
              <img src={sec2Desktop} className='desktop' alt="" width={790} />
              <img src={sec2DesktopLg} className='desktop-lg' alt="" width={1141} />
            </ImageContainer>
            <InfoContainerSectionTwo>
              <p>
                <div />offering a wide range of services and experts with extensive experience and expertise in creating optimal acoustic environments. The office offers various services related to acoustics design, including environmental noise assessments, architectural acoustics design, and audio-visual systems design. Their team of <span>experts</span> includes acousticians and designers who work together to create innovative solutions for optimal room acoustics.
              </p>
              <p>
                <div />When it comes to room acoustics design, pOint arkitekture takes <span>a holistic approach</span>, considering factors such as room geometry, material selection, and sound system design to achieve the desired acoustic environment. They also utilise advanced modelling and simulation tools to predict acoustic performance and optimise designs before construction begins.
              </p>
              <p>
                <div />pOint arkitekture has worked on a wide range of projects related to room acoustics, including halls, conferences, and corporate meeting rooms. They have a proven track record of success in creating <span>high-quality acoustical environments</span> that meet the specific needs of their clients.
              </p>
            </InfoContainerSectionTwo>
          </SectionTwo>
        </InitialContainer>
      </div>
    </LayoutDefault >
  )
}

export default Acoustics