import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';

import sec1Mobile from '../../assets/energy/mobile/section-1/mobile.svg'
import sec1DesktopSm from '../../assets/energy/mobile/section-1/desktop-sm.svg'
import sec1Desktop from '../../assets/energy/mobile/section-1/desktop.svg'
import sec1DesktopMd from '../../assets/energy/mobile/section-1/desktop-md.svg'
import sec1DesktopLg from '../../assets/energy/mobile/section-1/desktop-lg.svg'

import sec2Mobile from '../../assets/energy/mobile/section-2/mobile.svg'
import sec2DesktopSm from '../../assets/energy/mobile/section-2/desktop-sm.svg'
import sec2Desktop from '../../assets/energy/mobile/section-2/desktop.svg'
import sec2DesktopMd from '../../assets/energy/mobile/section-2/desktop-md.svg'
import sec2DesktopLg from '../../assets/energy/mobile/section-2/desktop-lg.svg'


import PageLoading from '../../components/utils/PageLoading';
import { ImageContainer, InfoContainerSectionOne, InfoContainerSectionTwo, InitialContainer, SectionOne, SectionTwo } from './Energy.styles';

function Energy() {
  const subMenuItems = [
    { title: "sustainability", href: "/expertise/sustainability" },
    { title: "energy-efficiency", href: "/expertise/energy-efficiency" },
    { title: "daylight", href: "/expertise/daylight" },
    { title: "acoustics", href: "/expertise/acoustics" },
    {
      title: "computational-simulation",
      href: "/expertise/computational-simulation",
    },
  ];

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }


  return (
    <LayoutDefault
      subMenuActiveItem="energy-efficiency"
      page="expertise"
      className='overflow-y-auto'
      subMenuItems={subMenuItems}
      menuburgerColor='white-burger'
    >
      <div className='bg-[#477163] md:pb-28'>
        <InitialContainer className='bg-[#477163]'>
          <SectionOne>
            <ImageContainer>
              <img src={sec1Mobile} className='mobile' alt="" width={342} />
              <img src={sec1DesktopSm} className='desktop-sm' alt="" width={526} />
              <img src={sec1DesktopMd} className='desktop-md' alt="" width={600} />
              <img src={sec1Desktop} className='desktop' alt="" width={844} />
              <img src={sec1DesktopLg} className='desktop-lg' alt="" width={1214} />
            </ImageContainer>
            <InfoContainerSectionOne>
              <h2>
                pOint arkitekture has developed a solid commitment to
                <span> energy efficiency...</span>
              </h2>
            </InfoContainerSectionOne>
          </SectionOne>
        </InitialContainer>

        <div className=" sticky inset-0 pointer-events-none z-50 -mt-[5%] hidden md:flex justify-end pr-32">
          <div className="md:block hidden w-12 h-24  caret2 bg-[#26483E] opacity-70" ></div>
        </div>

        <InitialContainer className='bg-[#477163]'>
          <SectionTwo>
            <InfoContainerSectionTwo>
              <p>
                <div />The company has set ambitious energy reduction targets for its office and has worked with clients to design energy-efficient and sustainable buildings.
              </p>
              <p>
                <div />pOint arkitekture's approach to energy efficiency is focused on using <span>a range of strategies</span> to reduce a building's energy use, including passive design strategies, such as natural ventilation, daylighting, and shading as the integration of renewable energy technologies.
              </p>
            </InfoContainerSectionTwo>
            <ImageContainer>
              <img src={sec2Mobile} className='mobile pb-12' style={{ 'marginTop': '0' }} alt="" width={310} />
              <img src={sec2DesktopSm} className='desktop-sm table mx-auto' alt="" width={570} />
              <img src={sec2DesktopMd} className='desktop-md table mx-auto' alt="" width={650} />
              <img src={sec2Desktop} className='desktop table mx-auto' alt="" width={980} />
              <img src={sec2DesktopLg} className='desktop-lg table mx-auto' alt="" width={1288} />
            </ImageContainer>

          </SectionTwo>
        </InitialContainer>

        <InitialContainer className='bg-[#477163]'>
          <SectionTwo>
            <InfoContainerSectionTwo>
              <p>
                <div />One of the key strategies that pOint arkitekture uses to achieve energy efficiency is <span>building energy modelling</span>. This involves using computer simulations to model a building's energy use and to identify opportunities to reduce energy consumption. By using energy modelling, pOint arkitekture is able to optimise a building's energy performance at the design stage, before construction even begins. The company also strongly emphasises designing adaptable and resilient buildings, allowing them to respond to changing energy efficiency requirements and new technologies.
              </p>
              <p>
                <div /> Overall, pOint arkitekture 's approach to energy efficiency reflects the company's commitment to creating innovative, sustainable, and energy-efficient buildings that have <span>a minimal environmental impact</span>. By incorporating energy efficiency into all aspects of their design practices, pOint arkitekture is able to design buildings that are not only aesthetical and functional but also sustainable and energy efficient.
              </p>
            </InfoContainerSectionTwo>
          </SectionTwo>
        </InitialContainer>
      </div>
    </LayoutDefault >
  )
}

export default Energy