import { useState } from "react";
import closeIcon from '../assets/icons/close-icon.png'
import fbIcon from '../assets/icons/fb-icon.png'
import instaIcon from '../assets/icons/insta-icon.png'
import linkedIcon from '../assets/icons/linkedin-icon.png'
import { Link } from "react-router-dom";


export default function Menu({
  show = false,
  setShowMenu,
  menuItems,
  page = "",
}) {
  const [activeItem, setActiveItem] = useState(page);


  return (
    <>
      <div className="fixed bg-black top-0 left-0 right-0 bottom-0 inset-0 h-screen z-[99999]">
        <button className={`text-white cursor-pointer absolute top-[30px] right-[40px] w-8 h-8 z-30 hamburger hamburger--spring ${show && 'is-active'}`} type="button" onClick={() => setShowMenu(false)}>
          <span className="hamburger-box text-white">
            <span className="hamburger-inner text-white"></span>
          </span>
        </button>
        <nav className="container relative flex flex-col place-items-center md:place-items-end justify-center ms-auto bg-black text-white spaxe-x-8 text-xl w-full h-full">
          <ul className="font-bold w-full pr-8 text-2xl text-right md:text-5xl">
            {menuItems.map((item, index) => (
              <li key={index}>
                {item.page === "contact" && (
                  <Link
                    className={` ${item.page === page ? "text-white " : ""
                      }`}
                    to={item.href}
                  >
                    {item.title}
                  </Link>
                )}
                {item.page !== "contact" && (
                  <button
                    className={` ${item.page === page ? "text-white  " : ""
                      }`}
                    type="button"
                    onClick={() => setActiveItem(item.page)}
                  >
                    {item.title}
                  </button>
                )}
                <ul className="space-y-2 text-sm pt-2 pb-4 font-light">
                  {activeItem === item.page &&
                    item.children.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <a href={subItem.href}>{subItem.title}</a>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>

          <ul className="w-[100%] absolute bottom-[30px] container  flex space-x-10  flex-row justify-center  items-center  md:items-start mt-8">
            <li className="border-[1px] rounded-full p-1">
              <a href="">
                <img src={fbIcon} />
              </a>
            </li>
            <li className="border-[1px] rounded-full p-1">
              <a href="">
                <img src={instaIcon} />
              </a>
            </li>
            <li className="border-[1px] rounded-full p-1">
              <a href="">
                <img src={linkedIcon} />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
