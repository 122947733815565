import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import Client from '../../components/Client';
import albesLogo from '../../assets/images/clients/albes.png'
import clientLogo from '../../assets/images/clients/01.png'
import renoLogo from '../../assets/images/clients/reno.png'
import ancorLogo from '../../assets/images/clients/anchor.png'
import PageLoading from '../../components/utils/PageLoading';
import { ClientsContainer, ClientsGrid } from './Clients.styles';

import desktopLg from '../../assets/clients/desktop-lg.svg'
import desktop from '../../assets/clients/desktop.svg'
import desktopMd from '../../assets/clients/desktop-md.svg'
import mobile from '../../assets/clients/mobile.svg'

function Clients() {
  const [loading, setLoading] = useState(true)
  const subMenuItems = [
    { title: "profile", href: "/about/profile" },
    { title: "people", href: "/about/people" },
    { title: "clients", href: "/about/clients" },
  ];

  const clients = [
    {
      name: "Planet",
      logo: "",
      bg: "bg-black text-white",
    },
    {
      name: "",
      logo: albesLogo,
      bg: "bg-white",
    },
    {
      name: "Sergio Hotel",
      logo: "",
      bg: "bg-[#ececec]",
    },
    {
      name: "Seaside <br>Residence II",
      logo: "",
      bg: "bg-[#ececec]",
    },
    {
      name: "ASR Invest",
      logo: "",
      bg: "bg-[#ececec]",
    },
    {
      name: "Roma Rio",
      logo: "",
      bg: "bg-[#ececec]",
    },
    {
      name: "Seaside <br>Residence I",
      logo: "",
      bg: "bg-black text-white",
    },
    {
      name: "Limani",
      logo: "",
      bg: "bg-[#ececec]",
    },
    {
      name: "",
      logo: clientLogo,
      bg: "bg-white",
    },
    {
      name: "White Roads",
      logo: "",

    },
    {
      name: "Edra 06",
      logo: "",
      bg: "bg-[#ececec]",

    },
    {
      name: "",
      logo: renoLogo,
      bg: "bg-white"
    },
    {
      name: "Logic Group",
      logo: "",
      bg: "bg-[#ececec]"
    },
    {
      name: "AR-Kam  <br>Cosntruction",
      logo: "",
    },
    {
      name: "",
      logo: ancorLogo,
      bg: "bg-white"
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }

  return (
    <LayoutDefault
      color="fill-black"
      subMenuActiveItem="clients"
      page="about"
      subMenuItems={subMenuItems}
      menuburgerColor='dark-burger'
      className='overflow-y-auto md:overflow-y-hidden'
    >
      <div className='hidden p-[7vh] pe-[10vh] mx-auto md:table h-screen pointer-events-none'>
        <img src={desktopLg} alt="" className='object-scale-down' />
      </div>
      <div className='table pt-[6vh] mx-auto md:hidden w-[100%] pointer-events-none'>
        <img src={mobile} alt="" className='object-scale-down' />
      </div>
      {/* <ClientsContainer>
        <ClientsGrid className="grid grid-cols-2 md:grid-cols-5 h-full gap-1 place-items-center items-center">
          {clients.map((client, index) => (
            <Client key={index} name={client.name} logo={client.logo} bg={client.bg} />
          ))}
        </ClientsGrid>
      </ClientsContainer> */}
    </LayoutDefault>
  )
}

export default Clients