import React, { useState } from 'react'
import Menu from '../components/Menu';
import BurgerIcon from '../components/icons/BurgerIcon';
import Loading from '../components/Loading';
import Footer from '../components/Footer';
import logo from '../assets/logo.svg'
import { Link } from 'react-router-dom';
import { LayoutContainer, ListContainer, MainMenuList, MenuBottom, Menubar, SubMenuList, TopMenubar } from './Layout.styles';


function LayoutDefault({
  children,
  className = "",
  page = "",
  pageTitle = page,
  subMenuItems = [],
  subMenuActiveItem = "",
  description = "point arkitekture is an architectural design office based in Tirana and Pristina since 2007. We believe our office demonstrates its commitment to building performance in a creative way, enhancing the sense of environment, human comfort and ecology.",
  color = "md:fill-white",
  ogImage = "https://pointarkitekture.com/logo-big.jpg",
  menuburgerColor = ""
}) {

  const menuItems = [
    {
      title: "about",
      href: "/about/profile",
      page: "about",
      children: [
        { title: "profile", href: "/about/profile" },
        { title: "people", href: "/about/people" },
        { title: "clients", href: "/about/clients" },
      ],
    },
    {
      title: "projects",
      href: "/projects",
      page: "projects",
      children: [
        {
          title: "all projects",
          href: "/projects",
          slug: "",
        },
        {
          title: "commercial",
          href: "/projects/commercial",
          slug: "commercial",
        },
        {
          title: "residential",
          href: "/projects/residential",
          slug: "residential",
        },
        { title: "hotel", href: "/projects/hotel", slug: "hotel" },
        {
          title: "industrial",
          href: "/projects/industrial",
          slug: "industrial",
        },
        { title: "urban", href: "/projects/urban", slug: "urban" },
        { title: "landscape", href: "/projects/landscape", slug: "landscape" },
      ],
    },
    {
      title: "expertise",
      href: "/expertise/sustainability",
      page: "expertise",
      children: [
        { title: "sustainability", href: "/expertise/sustainability" },
        { title: "energy-efficiency", href: "/expertise/energy-efficiency" },
        { title: "daylight", href: "/expertise/daylight" },
        { title: "acoustics", href: "/expertise/acoustics" },
        {
          title: "computational-simulation",
          href: "/expertise/computational-simulation",
        },
      ],
    },
    {
      title: "ideas",
      href: "/ideas/publications",
      page: "ideas",
      children: [
        { title: "publications", href: "/ideas/publications" },
        { title: "news", href: "/ideas/news" },
        { title: "research", href: "/ideas/research" },
        { title: "innovation", href: "/ideas/innovation" },
        { title: "explore", href: "/ideas/explore" },
      ],
    },
    { title: "contact", href: "/contact", page: "contact", children: [] },
  ];

  const [showMenu, setShowMenu] = useState(false);


  return (
    <>
      <LayoutContainer className={`${showMenu === true && 'overflow-y-hidden'}`}>
        <TopMenubar className="md:hidden fixed z-[999] w-full md:static top-0 left-0 px-8 py-8 border-b bg-gray-50">
          <a href="/">
            <img className="h-12" src={logo} />
          </a>
          <button
            onClick={() => setShowMenu(true)}
            className="text-3xl cursor-pointer absolute top-8 right-4 w-12 h-12 z-30 pointer-events-auto"
          >
            <div className=" w-full h-full flex items-center justify-center ">
              <BurgerIcon color={'fill-black'} />
            </div>
          </button>
        </TopMenubar>
        <main className={`${className}`}>
          {children}
        </main>
        <MenuBottom>
          <Menubar>
            <ListContainer>
              <SubMenuList>
                {subMenuItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      className={` ${subMenuActiveItem === item.title
                        ? "text-black  underline"
                        : ""
                        } hover:underline hover:text-black ${item.page === page ? "underline" : ""
                        }`}
                      to={item.href}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
                <li>&nbsp;</li>
              </SubMenuList>
              <MainMenuList>
                {menuItems.map((item, index) => (
                  <li key={index} className=''>
                    <Link
                      className={`hover:text-black ${item.page === page ? "text-black underline " : ""
                        }`}
                      to={item.href}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </MainMenuList>
            </ListContainer>
            <Loading play={page === "home"} />

          </Menubar>
          <Footer />
          {/* <Loading play={page === "home"} /> */}


        </MenuBottom>
        {!showMenu && <div className="fixed inset-0 z-50  pointer-events-none">
          <div className=" w-full h-full flex items-center justify-center ">
            <button className={`cursor-pointer fixed top-[30px] right-[30px] w-12 h-12 z-30 pointer-events-auto hamburger hamburger--spring ${menuburgerColor} ${showMenu && 'is-active'}`} type="button" onClick={() => setShowMenu(true)}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            {/* <BurgerIcon color={color} /> */}
          </div>
        </div>}
        {showMenu && <Menu
          show={showMenu}
          setShowMenu={setShowMenu}
          menuItems={menuItems}
          subMenuItems={subMenuItems}
          color={color}
          page={page}
        />}
      </LayoutContainer>

    </>
  )
}

export default LayoutDefault