import styled from "styled-components";

export const InitialContainer = styled.section`
  position: relative;
  overflow: hidden;
  margin-top: -5px;
`;

export const SectionOne = styled.div`
  width: 450px;
  margin: 0px;
  margin-top: 50px;
  position: relative;
  padding-top: 20px;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 50px auto;
    margin-left: 20px;
    padding-bottom: 50px;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 50px auto;
    margin-left: 20px;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 50px auto;
    margin-left: 20px;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 50px auto;
    margin-left: 20px;
  }
`;

export const SectionTwo = styled.div`
  width: 450px;
  margin: 0px;
  margin-top: 0px;
  position: relative;
  padding-top: 20px;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 50px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 50px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 50px auto;
  }
`;

export const ImageContainer = styled.div`
  img.mobile {
    display: table;
    margin: 0 auto;
    margin-top: 100px;
    margin-right: 15%;
  }

  img.desktop-sm {
    display: none;
  }

  img.desktop-md {
    display: none;
  }

  img.desktop {
    display: none;
  }
  img.desktop-lg {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    &.daylight-img-2 {
      img {
        margin-top: 8%;
      }
    }

    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: block;
    }

    img.desktop-md {
      display: none;
    }
    img.desktop {
      display: none;
    }
    img.desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 1366px) {
    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: none;
    }

    img.desktop-md {
      display: block;
    }
    img.desktop {
      display: none;
    }
    img.desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 1920px) {
    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: none;
    }

    img.desktop-md {
      display: none;
    }
    img.desktop {
      display: block;
    }
    img.desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 2730px) {
    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: none;
    }

    img.desktop-md {
      display: none;
    }
    img.desktop {
      display: none;
    }
    img.desktop-lg {
      display: block;
    }
  }
`;

export const InfoContainerSectionOne = styled.div`
  width: 350px;
  text-align: end;
  margin-top: 20px;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: 13%;

  h2 {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;

    span {
      font-size: 25px;
      font-weight: 600;
      color: #f8eb30;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-top: -50px;
    text-align: end;
    width: 465px;
    margin-left: auto;
    margin-right: 9%;
    h2 {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 32px;
        font-weight: 600;
        color: #f8eb30;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    width: 530px;
    margin-left: auto;
    margin-right: 6%;
    margin-top: -50px;
    h2 {
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 36px;
        font-weight: 600;
        color: #f8eb30;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    width: 725px;
    margin-left: auto;
    margin-right: 2%;
    margin-top: -70px;
    h2 {
      font-size: 34px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 48px;
        font-weight: 600;
        color: #f8eb30;
      }
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1092px;
    margin-left: auto;
    margin-right: 0%;
    margin-top: -100px;
    h2 {
      font-size: 50px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 70px;
        font-weight: 600;
        color: #f8eb30;
      }
    }
  }
`;

export const InfoContainerSectionTwo = styled.div`
  width: 370px;
  position: relative;
  margin: 0 20px;
  padding-bottom: 25px;
  margin-top: 40px;
  order: 1;

  p {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 15px;
    letter-spacing: 0.5px;

    div {
      width: 35px;
      height: 3px;
      display: inline-block;
    }

    span {
      color: #f8eb30;
      font-size: 25px;
      line-height: 30px;
      font-weight: 800;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 390px;
    position: relative;
    margin: 0 auto;
    margin-left: 5%;

    p {
      position: relative;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;

      div {
        width: 35px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #f8eb30;
        font-size: 16px;
        line-height: 20px;
        font-weight: 800;
      }
    }

    &.content {
      margin-top: auto;
      margin-bottom: 5%;
    }
  }

  @media screen and (min-width: 1366px) {
    width: 440px;
    position: relative;
    margin: 0 auto;
    margin-left: 7%;

    p {
      position: relative;
      font-size: 18px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      line-height: 22px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #f8eb30;
        font-size: 20px;
        line-height: 22px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    width: 615px;
    position: relative;
    margin: 0 auto;
    margin-left: 7%;
    p {
      position: relative;
      font-size: 24px;
      line-height: 26px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #f8eb30;
        font-size: 26px;
        line-height: 26px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 2730px) {
    width: 885px;
    position: relative;
    margin: 0 auto;
    margin-left: 7%;
    p {
      position: relative;
      font-size: 38px;
      line-height: 46px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #f8eb30;
        font-size: 42px;
        line-height: 46px;
        font-weight: 800;
      }
    }
  }
`;
