import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import pubImg1 from '../../assets/images/ideas/publication-1.jpg'
import pubImg2 from '../../assets/images/ideas/publication-2.jpg'
import pubImg3 from '../../assets/images/ideas/publication-3.jpg'
import pubImg4 from '../../assets/images/ideas/publication-4.jpg'
import pubImg5 from '../../assets/images/ideas/publication-5.jpg'
import pubImg6 from '../../assets/images/ideas/publication-6.jpg'
import pubImg7 from '../../assets/images/ideas/publication-7.jpg'
import PageLoading from '../../components/utils/PageLoading'


function Publications() {
  const subMenuItems = [
    { title: "publications", href: "/ideas/publications" },
    { title: "news", href: "/ideas/news" },
    { title: "research", href: "/ideas/research" },
    { title: "innovation", href: "/ideas/innovation" },
    { title: "explore", href: "/ideas/explore" }
  ];

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }

  return (
    <LayoutDefault
      subMenuActiveItem="publications"
      page="ideas"
      subMenuItems={subMenuItems}
      className='overflow-y-auto'
      menuburgerColor='white-burger'
    >
      <div className='h-[83vh]'>
        <div className={`h-screen md:h-full relative publicatons-image-bg bg-no-repeat bg-center bg-cover overflow-hidden`}>
          <div className="container mx-auto h-full">
            <div className="flex h-full relative"></div>
          </div>
        </div>
        <div className=" hidden   sticky inset-0 pointer-events-none z-50 -mt-32 md:flex justify-end pr-32">
          <div className="md:block hidden w-12 h-32 caret6"></div>
        </div>
        <div className="h-auto font-quicksand relative bg-[#a89c8e] pt-[150px] md:pt-[20px] pb-[10px] md:pb-[50px] ">
          <div className="container mx-auto h-full ">
            <div className=" h-auto w-full md:w-auto block md:grid gap-4  pl-0 pr-0 md:pl-10 md:pr-40">
              <div className=" block md:flex flex-col-3 justify-center">
                <div className="flex-none w-[80%] mx-auto md:mx-0 md:w-[20%]">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Journal
                  </h3>
                  <img src={pubImg1} className="" />
                </div>
                <div className="block font-semibold text-[14px] md:flex-initial md:w-[34%] w-[80%] md:mx-0 mx-auto md:mt-0 mt-[30px] md:mb-0 text-justify space-x-0 md:space-x-10">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Description
                  </h3>
                  <div className="grid h-auto ">
                    <p className="items-end text-white text-[14px] leading-[17px] pt-0 md:pt-10">
                      Energy is an international, multi-disciplinary journal
                      in energy engineering and research. The journal aims to be
                      a leading peer-reviewed platform and an authoritative
                      source of information for analyses, reviews and
                      evaluations related to energy. The journal covers
                      mechanical engineering and thermal sciences research, with
                      a strong focus on energy analysis,
                      energy modelling and prediction, integrated energy
                      systems, energy planning and  management.
                    </p>
                    <span className="text-[16px] text-white mt-10">
                      <b>
                        8.857 <br></br>Impact Factor
                      </b>
                    </span>
                  </div>
                </div>
                <div className="flex-initial w-[80%] mx-auto md:mt-0 mt-[30px] md:mx-0  items-center md:w-[30%] space-x-0 md:space-x-10">
                  <h3 className="font-bold text-white uppercase text-2xl mb-5 text-center align-top">
                    Research Paper
                  </h3>
                  <p className="text-white texy-justify md:text-right my-[30px] md:my-28   font-semibold text-[14px] leading-[17px]">
                    Early design evaluation of low-rise school building
                    morphology on energy performance: Climatic contexts of
                    Southeast Europe. Energy, 269.
                  </p>
                </div>
              </div>
              <div className="block md:flex flex-col-3 justify-center md:mt-[0px] mt-0">
                <div className="flex-none w-[80%] mx-auto md:mx-0 md:w-[20%]">
                  <img src={pubImg2} />
                </div>
                <div className="block md:flex-initial mx-auto md:mx-0 w-[80%] md:w-[34%] text-justify space-x-0 mt-[30px] md:mt-0 md:space-x-10">
                  <h3 className="font-bold uppercase text-white hidden text-2xl mb-5 text-center"></h3>
                  <div className="grid h-full">
                    <p className="items-end text-white font-semibold text-[14px] leading-[17px] ">
                      Sustainable Cities and Society (SCS) is an international
                      journal focusing on fundamental and applied research to
                      design, understand, and promote environmentally
                      sustainable and socially resilient cities. We encourage
                      submission of cross-cutting, multi-disciplinary research
                      in the areas of Smart cities and resilient environments;
                      alternative / clean energy sources, energy distribution,
                      distributed energy generation, and energy demand
                      reduction/management; Energy efficient, low/zero carbon,
                      and green buildings/communities; Climate change mitigation
                      and adaptation in urban environments
                    </p>
                    <span className="  text-white">
                      <b>
                        10.696 <br></br>Impact Factor
                      </b>
                    </span>
                  </div>
                </div>
                <div className="flex-initial mx-auto md:mx-0 w-[80%] md:w-[30%] space-x-0 mt-[30px] md:mt-0 md:space-x-10">
                  <h3 className="font-bold text-white uppercase text-2xl mb-5 text-center"></h3>
                  <p className="text-white texy-justify md:text-right my-[30px] font-semibold md:my-28 text-[15px]">
                    Thermal and energy performance evaluation of underground
                    bunkers: An adaptive reuse approach. Sustainable Cities and
                    Society, 46.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-auto font-quicksand relative bg-[#a89c8e] pt-[20px] pb-[20px] md:pb-[50px]">
          <div className="container mx-auto h-full">
            <div className="h-auto block md:grid gap-4  pl-0 pr-0 md:pl-10 md:pr-40 justify-items-center">
              <div className="block md:flex flex-col-3 justify-center">
                <div className="flex-none md:w-[20%] mx-auto md:mx-0 w-[80%]">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Journal
                  </h3>
                  <img
                    src={pubImg3}
                    className="w-full"
                  />
                </div>

                <div className="flex-initial w-[80%] md:w-[34%] mx-auto md:mx-0 mt-[30px] md:mt-0 text-justify space-x-0 md:space-x-10 ">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Description
                  </h3>
                  <div className="grid h-full">
                    <p className="items-end text-white font-semibold text-[14px] leading-[17px]">
                      Energy and Buildings is an international journal
                      publishing articles explicitly linking energy use in
                      buildings. The aim is to present new research results and
                      new proven practices aimed at reducing the energy needs of
                      a building and improving indoor environment quality.
                      Energy and Buildings Journal considers and publishes
                      articles considerably advancing Building Science. Topics
                      covered include Energy demands and consumption in existing
                      and future buildings - prediction and validation, Energy
                      balances in building complexes (residential, commercial,
                      industrial, public and other buildings), and Energy
                      conservation in the built environment.
                    </p>
                    <span className=" text-white">
                      <b>
                        7.201<br></br>Impact Factor
                      </b>
                    </span>
                  </div>
                </div>
                <div className="flex-initial mx-auto md:mx-0 w-[80%] md:w-[30%] mt-[30px] md:mt-0 space-x-0 md:space-x-10">
                  <h3 className="font-bold text-white uppercase text-2xl mb-5 text-center">
                    Research Paper
                  </h3>
                  <p className="text-white  text-justify md:text-right my-[30px] md:my-28 font-semibold text-[14px] leading-[17px]">
                    The impact of vertical vegetation on thermal performance of
                    high-rise office building facades in Mediterranean climate.
                    Energy and Buildings, 236.{" "}
                  </p>
                </div>
              </div>
              <div className="block md:flex flex-col-3 justify-center">
                <div className="flex-none w-[80%] md:w-[20%] mx-auto md:mx-0">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center"></h3>
                  <img
                    src={pubImg4}
                    className="w-full"
                  />
                </div>
                <div className="flex-initial md:w-[34%] w-[80%] mt-[30px] md:mt-0 md:mx-0 mx-auto space-x-0 text-justify md:space-x-10 ">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center"></h3>
                  <div className="grid h-full">
                    <p className="items-end text-white text-[14px] leading-[17px] font-semibold">
                      The Journal of Building Engineering (JOBE) is an
                      interdisciplinary journal covering all aspects of science
                      and technology concerned with the whole life cycle of the
                      built environment, from the design phase to construction,
                      operation, performance, maintenance and deterioration.
                      JOBE only publishes papers where significant scientific
                      novelty is demonstrated. The journal covers but is not
                      limited to, the following topics: Computer-aided design
                      and simulation, Energy efficiency (building envelope and
                      system), Indoor Environmental Quality (acoustic, visual,
                      thermal, moisture, air quality)
                    </p>
                    <span className=" text-white">
                      <b>
                        7.144 <br></br>Impact Factor
                      </b>
                    </span>
                  </div>
                </div>
                <div className="flex-initial md:w-[30%] w-[80%] md:mx-0 mx-auto space-x-0 md:space-x-10 mt-[30px] md:mt-0">
                  <h3 className="font-bold text-white uppercase text-2xl mb-5 text-center"></h3>
                  <p className="text-white md:text-right  text-left my-0 md:my-28 font-semibold text-[14px] leading-[17px]">
                    Energy performance optimization of traditional housing in
                    Mediterranean climate. Journal of Building Engineering, 45.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-auto relative font-quicksand bg-[#a89c8e] pt-[40px] pb-[50px] md:pb-[50px] ">
          <div className="container mx-auto h-full">
            <div className="h-auto grid gap-4  pl-0 pr-0 md:pl-10 md:pr-40 justify-items-center">
              <div className="block md:flex flex-col-3 justify-center">
                <div className="flex-none md:w-[20%] w-[80%] md:mx-0 mx-auto">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Journal
                  </h3>
                  <img src={pubImg5} />
                </div>
                <div className="flex-initial md:w-[34%] w-[80%] md:mx-0 mx-auto text-justify space-x-0 md:space-x-10 mt-[30px] md:mt-0">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Description
                  </h3>
                  <div className="grid h-full">
                    <p className="text-white items-end text-[14px] leading-[17px] font-semibold">
                      The journal, Renewable Energy, seeks to promote and
                      disseminate knowledge on the various topics and
                      technologies of renewable energy systems and components.
                      The journal aims to serve researchers, engineers,
                      economists, manufacturers, NGOs, associations and
                      societies to help them keep abreast of new developments in
                      their specialist fields and to apply alternative energy
                      solutions to current practices. The journal aims to be a
                      leading peer-reviewed platform and an authoritative source
                      of original research and reviews related to renewable
                      energy.
                    </p>
                    <span className=" text-white">
                      <b>
                        8.634<br></br>Impact Factor
                      </b>
                    </span>
                  </div>
                </div>
                <div className="flex-initial md:w-[30%] md:space-x-10 md:mx-0 w-[80%] mx-auto space-x-0 mt-[30px] md:mt-0">
                  <h3 className="font-bold text-white uppercase text-2xl mb-5 text-center">
                    Research Paper
                  </h3>
                  <p className="text-white text-left md:text-right my-0 md:my-28 text-[14px] leading-[17px] font-semibold">
                    Computational derivation of irradiance on building surfaces:
                    An empirically-based model comparison. Renewable Energy, 71,
                    185–192.
                  </p>
                </div>
              </div>
              <div className="block md:flex flex-col-3 justify-center">
                <div className="flex-none md:w-[20%] w-[80%] md:mx-0 mx-auto">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center"></h3>
                  <img src={pubImg6} />
                </div>
                <div className="flex-initial md:w-[34%] text-justify md:space-x-10 md:mt-0 md:mx-0 w-[80%] mx-auto mt-[30px] space-x-0 ">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center"></h3>
                  <div className="h-full grid">
                    <p className="text-white items-end text-[14px] leading-[17px] font-semibold">
                      Solar Energy, the official journal of the International
                      Solar Energy Society®, is devoted exclusively to the
                      science and technology of solar energy applications. For
                      over 60 years, ISES members from more than 100 countries
                      have undertaken the product research and development that
                      has helped the renewable energy industry to grow. ISES
                      serves as a center for information on research and
                      development in solar energy through its publications.
                    </p>
                    <span className=" text-white">
                      <b>
                        7.188 <br></br>Impact Factor
                      </b>
                    </span>
                  </div>
                </div>
                <div className="flex-initial w-[80%] md:w-[30%] md:space-x-10 space-x-0 md:mx-0 mx-auto mt-[30px] md:mt-0">
                  <h3 className="font-bold text-white uppercase text-2xl mb-5 text-center"></h3>
                  <p className="text-white text-left md:text-right my-0 md:my-28 text-[14px] leading-[17px] font-semibold">
                    Computing diffuse fraction of global horizontal solar
                    radiation: A model comparison. Solar Energy, 86(6),
                    1796–1802.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full relative font-quicksand bg-[#a89c8e] pt-0 md:pt-[50px] pb-[100px] md:pb-0">
          <div className="container mx-auto h-full">
            <div className="grid gap-4  pl-0 pr-0 md:pl-10 md:pr-40 h-auto md:h-80 justify-items-center ">
              <div className="block md:flex flex-col-3 justify-center">
                <div className="flex-none md:w-[20%] w-[80%] mx-auto md:mx-0">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Journal
                  </h3>
                  <img
                    src={pubImg7}
                    className="md:w-auto w-full"
                  />
                </div>
                <div className="flex-initial md:w-[34%] w-[80%] mx-auto mt-[30px] md:mx-0 md:mt-0 text-justify space-x-0 md:space-x-10">
                  <h3 className="font-bold uppercase text-white text-2xl mb-5 text-center">
                    Description
                  </h3>
                  <div className="h-full grid">
                    <p className="text-white items-end text-[14px] leading-[17px] font-semibold">
                      Building Simulation is an International Journal that
                      publishes original, high-quality, peer-reviewed research
                      papers and reviews articles dealing with the modelling and
                      simulation of buildings, including their systems. The goal
                      is to promote building science and technology to such a
                      level that modelling will eventually be used in every
                      aspect of building construction as a routine instead of an
                      exception. Of particular interest are papers reflecting
                      recent developments and applications of modelling tools
                      and their impact on building science and technology
                      advances.
                    </p>

                    <span className=" text-white">
                      <b>
                        4.008<br></br>Impact Factor
                      </b>
                    </span>
                  </div>
                </div>
                <div className="flex-initial md:w-[30%] md:space-x-10 mt-[30px] md:mt-0 space-x-0 w-[80%] mx-auto md:mx-0">
                  <h3 className="font-bold text-white uppercase text-2xl mb-5 text-center">
                    Research Paper
                  </h3>
                  <p className="text-white  text-left md:text-right my-0 md:my-16 text-[14px] leading-[17px] font-semibold">
                    A simple model for the derivation of illuminance values from
                    global solar radiation data. Building Simulation, 6(4),
                    379–383.
                  </p>
                  <p className="text-white text-[14px] leading-[17px] font-semibold text-left md:text-right mt-10">
                    A comparison of luminous efficacy models based on data from
                    Vienna, Austria. Building Simulation, 4(3), 183–188.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault >
  )
}

export default Publications