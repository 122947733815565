import React, { useEffect, useState } from 'react'
import LayoutDefault from '../layout/LayoutDefault'
import phoneIcon from '../assets/icons/phone-icon.svg'
import sendIcon from '../assets/icons/sendIcon.svg'
import fullNameIcon from '../assets/icons/fullname-icon.svg'
import PageLoading from '../components/utils/PageLoading'
import locIcon from '../assets/icons/loc-contact.svg'

function Contact() {
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [])

  if (loading) {
    return <PageLoading />
  }

  return (
    <LayoutDefault page="contact" className="bg-black" menuburgerColor='white-burger'>
      <div className='w-screen h-[83vh] overflow-y-auto bg-black'>
        <div className="container grid grid-cols-1 md:grid-cols-2 text-white place-items-center h-full mx-auto">
          <div className="w-full md:w-1/2 flex flex-col justify-center pt-40 pl-8 pr-8 md:pt-0 md:pl-8 md:pr-0">
            <div className="space-y-12">
              <div>
                <div className="text-4xl font-bold">nuis</div>
                L91717015O
              </div>
              <div>
                <div className="text-4xl font-bold flex items-center gap-2">address <img src={locIcon} width={20} /></div>
                <a href="https://www.google.com/maps/place/41%C2%B019'31.9%22N+19%C2%B048'51.2%22E/@41.325526,19.8116381,17z/data=!3m1!4b1!4m4!3m3!8m2!3d41.325526!4d19.814213?entry=ttu" target='_blank' className='text-inherit'>
                  rruga: “Shyqyri Berxolli” Rezidenca: “Inter-Cosat”
                  <br />
                  Shkalla A, nr 30 A Njesia nr: 10 Tirane, Albania
                </a>

              </div>
              <div>
                <div className="text-4xl font-bold">mob</div>
                +355 696444255
                <br /> +355 67 3760080
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 pl-8 pr-8 pt-8 pb-[100px] md:pb-0  md:p-0 flex items-center h-full">
            <div className="w-full">
              <div className="text-4xl font-bold">contact us</div>
              <div>
                <form>
                  <div className="flex flex-col flex-wrap pt-6 md:flex-row">
                    <div className="w-full pb-4">
                      <div>
                        <label htmlFor="fullName" className="flex items-center relative">
                          <div className="absolute z-10 pl-4 text-primary">
                            <img src={fullNameIcon} />
                          </div>
                          <div className="flex w-full flex-col relative">
                            <input
                              className="drop-shadow-customGray border-secondary flex h-12 w-full items-center  border border-opacity-10 px-4 pl-12 filter "
                              type="text"
                              name="fullName"
                              id="fullName"
                              placeholder="Full name"
                            />
                          </div>
                        </label>
                        <p className="py-1 text-sm text-primary"></p>
                      </div>
                    </div>
                    <div className="w-full pb-4">
                      <div>
                        <label
                          htmlFor="phone_email"
                          className="flex items-center relative"
                        >
                          <div className="absolute z-10 pl-4 text-primary">
                            <img src={phoneIcon} />
                          </div>
                          <div className="flex w-full flex-col">
                            <input
                              className="drop-shadow-customGray border-secondary flex h-12 w-full items-center  border border-opacity-10 px-4 pl-12 filter"
                              type="text"
                              name="phone_email"
                              id="phone_email"
                              placeholder="Phone number or Email"
                            />
                          </div>
                        </label>
                        <p className="py-1 text-sm text-primary"></p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col ">
                    <div>
                      <div className="w-full">
                        <textarea
                          className="drop-shadow-customGray border-secondary h-36 w-full border border-opacity-10 p-4 filter"
                          name="textareas"
                          placeholder="Your message goes here..."
                        ></textarea>
                        <p className="py-1 text-sm text-primary"></p>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-8 pt-6 md:flex-row">
                    <div className="w-full">
                      <button className="w-full">
                        <a className="flex cursor-pointer items-center gap-8 bg-black px-8 py-5 hover:bg-gray-600">
                          <img src={sendIcon} alt="" />
                          <div className="flex flex-1 justify-center text-xl font-light text-white">
                            Send Message
                          </div>
                        </a>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default Contact