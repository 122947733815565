import React from 'react'

function Client({
  name,
  logo = "",
  bg = "bg-black text-white",
}) {
  return (
    <div
      className={`${bg} w-full h-[100px] md:min-h-[200px] md:h-full flex  justify-center  uppercase font-bold`}
    >
      {logo === "" && (
        <p
          className="text-center self-center"
          dangerouslySetInnerHTML={{ __html: name }}
        />
      )}
      {logo !== "" && <img src={logo} className=" object-contain " />}
    </div>
  )
}

export default Client