import styled from "styled-components";

export const InitialContainer = styled.section`
  position: relative;
  overflow: hidden;
  padding: 60px 0;

  &.first-container {
    padding-top: 0 !important;
  }
`;

export const SectionOne = styled.div`
  width: 450px;
  margin: px auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 20px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 20px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 20px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 20px auto;
  }
`;

export const SectionTwo = styled.div`
  width: 450px;
  margin: 0px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 20px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 20px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 20px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 50px auto;
  }
`;

export const SectionThree = styled.div`
  width: 450px;
  margin: 0px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 50px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 50px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 50px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 50px auto;
  }
`;

export const SectionFour = styled.div`
  width: 450px;
  margin: 30px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
  }
`;

export const SectionFive = styled.div`
  width: 450px;
  margin: 0px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
  }
`;

export const SectionSix = styled.div`
  width: 450px;
  margin: 30px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 0px auto;
  }
`;

export const SectionSeven = styled.div`
  width: 450px;
  margin: 30px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 0px auto;
  }
`;

export const SectionEight = styled.div`
  width: 450px;
  margin: 30px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 0px auto;
  }
`;

export const SectionNine = styled.div`
  width: 450px;
  margin: 30px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 0px auto;
  }
`;

export const SectionTen = styled.div`
  width: 450px;
  margin: 30px auto;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1366px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 1920px) {
    width: 90%;
    margin: 0px auto;
  }

  @media screen and (min-width: 2730px) {
    width: 90%;
    margin: 0px auto;
  }
`;

export const ImageContainer = styled.div`
  img.mobile {
    display: block;
    margin-top: 100px;
  }

  img.desktop-sm {
    display: none;
  }

  img.desktop-md {
    display: none;
  }

  img.desktop {
    display: none;
  }
  img.desktop-lg {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: block;
    }

    img.desktop-md {
      display: none;
    }
    img.desktop {
      display: none;
    }
    img.desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 1366px) {
    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: none;
    }

    img.desktop-md {
      display: block;
    }
    img.desktop {
      display: none;
    }
    img.desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 1920px) {
    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: none;
    }

    img.desktop-md {
      display: none;
    }
    img.desktop {
      display: block;
    }
    img.desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 2730px) {
    img.mobile {
      display: none;
    }

    img.desktop-sm {
      display: none;
    }

    img.desktop-md {
      display: none;
    }
    img.desktop {
      display: none;
    }
    img.desktop-lg {
      display: block;
    }
  }
`;

export const InfoContainerSectionTwo = styled.div`
  width: 85%;
  text-align: center;
  margin-top: 0px;
  h2 {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;

    span {
      font-size: 26px;
      font-weight: 600;
      color: #c4bdb4;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-top: -5%;
    text-align: end;
    h2 {
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 32px;
        font-weight: 600;
        color: #c4bdb4;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    h2 {
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 36px;
        font-weight: 600;
        color: #c4bdb4;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    h2 {
      font-size: 34px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 48px;
        font-weight: 600;
        color: #c4bdb4;
      }
    }
  }

  @media screen and (min-width: 2730px) {
    h2 {
      font-size: 50px;
      font-weight: 500;
      color: #ffffff;

      span {
        font-size: 70px;
        font-weight: 600;
        color: #c4bdb4;
      }
    }
  }
`;

export const InfoContainerSectionThree = styled.div`
  width: 370px;
  position: relative;
  margin: 0 20px;
  padding-bottom: 25px;

  p {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 15px;

    div {
      width: 35px;
      height: 3px;
      display: inline-block;
    }

    span {
      color: #c4bdb4;
      font-size: 25px;
      line-height: 30px;
      font-weight: 800;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 630px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 14px;
      line-height: 26px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;

      div {
        width: 35px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 16px;
        line-height: 26px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    width: 765px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 18px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      line-height: 31.8px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 20px;
        line-height: 31.8px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    width: 1045px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 24px;
      line-height: 43px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 26px;
        line-height: 43px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1696px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 38px;
      line-height: 70px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 42px;
        line-height: 70px;
        font-weight: 800;
      }
    }
  }
`;

export const InfoContainerSectionFour = styled.div`
  width: 370px;
  position: relative;
  margin: 0 20px;
  padding-bottom: 25px;

  p {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 25px;

    div {
      width: 35px;
      height: 3px;
      display: inline-block;
    }

    span {
      color: #c4bdb4;
      font-size: 25px;
      line-height: 30px;
      font-weight: 800;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 630px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 14px;
      line-height: 26px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;

      div {
        width: 35px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 16px;
        line-height: 26px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    width: 765px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 18px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      line-height: 31.8px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 20px;
        line-height: 31.8px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    width: 1045px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 24px;
      line-height: 43px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 26px;
        line-height: 43px;
        font-weight: 800;
      }
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1696px;
    position: relative;
    margin: 0 auto;

    p {
      position: relative;
      font-size: 38px;
      line-height: 70px;
      color: #ffffff;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 15px;
      letter-spacing: 0.5px;

      div {
        width: 65px;
        height: 3px;
        display: inline-block;
      }

      span {
        color: #c4bdb4;
        font-size: 42px;
        line-height: 70px;
        font-weight: 800;
      }
    }
  }
`;

export const InfoContainerSectionFive = styled.div`
  width: 215px;
  text-align: end;
  margin-left: auto;
  margin-right: 50px;
  padding-bottom: 60px;
  margin-top: -40px;
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 0px;
    text-align: end;
    width: 90%;
    margin-left: 0;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1366px) {
    width: 85%;
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 85%;
    h2 {
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 2730px) {
    h2 {
      font-size: 52px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 40px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`;

export const InfoContainerSectionSix = styled.div`
  width: 370px;
  text-align: end;
  margin-left: auto;
  margin-right: 50px;
  padding-bottom: 60px;
  margin-top: 20px;
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 0px;
    text-align: end;
    width: 550px;
    margin-left: auto;
    margin-right: 11%;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1366px) {
    margin-right: 11%;
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 886px;
    margin-right: 8%;
    h2 {
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1160px;
    margin-right: 4%;
    h2 {
      font-size: 52px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 40px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`;

export const InfoContainerSectionSeven = styled.div`
  width: 337px;
  text-align: end;
  margin-left: auto;
  margin-right: 50px;
  padding-bottom: 60px;
  margin-top: 20px;
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 0px;
    text-align: end;
    width: 550px;
    margin-left: auto;
    margin-right: 11%;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1366px) {
    margin-right: 11%;
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 886px;
    margin-right: 8%;
    h2 {
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1160px;
    margin-right: 4%;
    h2 {
      font-size: 52px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 40px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`;

export const InfoContainerSectionEight = styled.div`
  width: 356px;
  text-align: end;
  margin-left: auto;
  margin-right: 50px;
  padding-bottom: 60px;
  margin-top: 20px;
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: -80px;
    text-align: end;
    width: 550px;
    margin-left: auto;
    margin-right: 11%;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1366px) {
    margin-right: 11%;
    margin-top: -90px;
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 886px;
    margin-right: 8%;
    margin-top: -100px;
    h2 {
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1200px;
    margin-right: 4%;
    margin-top: -130px;
    h2 {
      font-size: 52px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 40px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`;

export const InfoContainerSectionNine = styled.div`
  width: 356px;
  text-align: end;
  margin-left: auto;
  margin-right: 50px;
  padding-bottom: 60px;
  margin-top: 20px;
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: -80px;
    text-align: end;
    width: 550px;
    margin-left: auto;
    margin-right: 11%;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1366px) {
    margin-right: 11%;
    margin-top: -90px;
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 886px;
    margin-right: 8%;
    margin-top: -100px;
    h2 {
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1200px;
    margin-right: 4%;
    margin-top: -130px;
    h2 {
      font-size: 52px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 40px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`;

export const InfoContainerSectionTen = styled.div`
  width: 356px;
  text-align: end;
  margin-left: auto;
  margin-right: 50px;
  padding-bottom: 60px;
  margin-top: 20px;
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: -80px;
    text-align: end;
    width: 550px;
    margin-left: auto;
    margin-right: 11%;
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1366px) {
    margin-right: 11%;
    margin-top: -90px;
    h2 {
      font-size: 28px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 886px;
    margin-right: 8%;
    margin-top: -100px;
    h2 {
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  @media screen and (min-width: 2730px) {
    width: 1200px;
    margin-right: 4%;
    margin-top: -130px;
    h2 {
      font-size: 52px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 40px;
    }

    p {
      color: #fff;
      text-align: right;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`;
