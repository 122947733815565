import React, { useEffect, useState } from 'react'

import img1 from '../../assets/images/people/fitim-miftari.jpg'
import img2 from '../../assets/images/people/krenar-haruni.jpg'
import img3 from '../../assets/images/people/getoar-rizahu.jpg'
import img4 from '../../assets/images/people/kreshnik-ibrahimi.jpg'
import img5 from '../../assets/images/people/arburim-miftari.jpg'
import img6 from '../../assets/images/people/rejdi-pjeternikaj.jpg'
import img7 from '../../assets/images/people/kujtim-skenderaj.jpg'
import img8 from '../../assets/images/people/rodi-gorani.png'
import img9 from '../../assets/images/people/orjana-balla.jpg'
import img10 from '../../assets/images/people/katerina-lika.jpg'
import img11 from '../../assets/images/people/egzon-jakupi.jpg'
import img12 from '../../assets/images/people/sokol-dervishi.jpg'
import img13 from '../../assets/images/people/martin-shameti.jpg'
import LayoutDefault from '../../layout/LayoutDefault'
import Person from '../../components/Person'
import iconImg from '../../assets/images/circle-icon.svg'
import PageLoading from '../../components/utils/PageLoading'
import { PeopleContainer, PeopleGrid, PeopleText } from './People.style'
import Slider from 'react-slick'



function People() {
  const subMenuItems = [
    { title: "profile", href: "/about/profile" },
    { title: "people", href: "/about/people" },
    { title: "clients", href: "/about/clients" },
  ];

  const people = [
    {
      name: "Fitim Miftari",
      role: "project manager",
      image: img1,
    },
    {
      name: "Krenar Haruni",
      role: "architect",
      image: img2,
    },
    {
      name: "Getoar Rizahu",
      role: "architect",
      image: img3,
    },
    {
      name: "Kreshnik Ibrahimi",
      role: "architect",
      image: img4,
    },
    {
      name: "Arburim Miftari",
      role: "architect",
      image: img5,
    },
    {
      name: "Rejdi Pjeternikaj",
      role: "architect",
      image: img6,
    },
    {
      name: "Kujtim Skenderaj",
      role: "architect",
      image: img7,
    },
    {
      name: "Rodi Gorani",
      role: "architect",
      image: img8,
    },
    {
      name: "Orjana Balla",
      role: "project manager",
      image: img9,
    },
    {
      name: "Katerina Lika",
      role: "architect",
      image: img10,
    },
    {
      name: "Egzon Jakupi",
      role: "architect",
      image: img11,
    },
    {
      name: "Sokol Dervishi",
      role: "partner",
      image: img12,
    },
    {
      name: "Martin Shameti",
      role: "founder",
      image: img13,
    },
  ];

  const [loading, setLoading] = useState(true)

  const slickSettings = {
    dots: false,
    fade: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    slidesToShow: 5,
    rows: 3,
    slidesPerRow: 5,
    className: 'people-slide',
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }

  return (
    <LayoutDefault
      color="fill-black"
      subMenuActiveItem="people"
      page="about"
      subMenuItems={subMenuItems}
      menuburgerColor='dark-burger'
      className='block relative h-full'
    >
      <PeopleContainer>
        <PeopleGrid>
          {people.slice(0, 5).map((person, index) => (
            <Person
              key={index}
              name={person.name}
              image={person.image}
              role={person.role}
            />
          ))}
          {people.slice(5, 8).map((person, index) => (
            <Person
              key={index}
              name={person.name}
              image={person.image}
              role={person.role}
            />
          ))}
          <PeopleText>
            diversity s<span></span>urcing
          </PeopleText>
          {people.slice(8).map((person, index) => (
            <Person
              key={index}
              name={person.name}
              image={person.image}
              role={person.role}
            />
          ))}
        </PeopleGrid>
      </PeopleContainer>
    </LayoutDefault>
  )
}

export default People