import styled from "styled-components";

export const LayoutContainer = styled.div`
  position: relative;

  main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 83vh;

    @media screen and (max-width: 767px) {
      height: 100%;
      min-height: 100vh;
      position: relative;
    }
  }
`;

export const TopMenubar = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MenuBottom = styled.div`
  position: fixed;
  bottom: 0vh;
  right: 0;
  left: 0;
  z-index: 9999;
  padding-top: 20px;
  background-color: #ffffff;
  width: 100%;
`;

export const Menubar = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 30px;
  padding-top: 0px;
  width: 100%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const SubMenuList = styled.ul`
  position: relative;
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 0px;

  li {
    padding: 0px 12px;
    font-family: "quicksand";
    font-weight: 600;
    color: #bfbfbf;
    font-size: 16px;
  }
  @media screen and (min-width: 1200px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 0px;

    li {
      padding: 0px 12px;
      font-family: "quicksand";
      font-weight: 600;
      color: #bfbfbf;
      font-size: 16px;
    }
  }

  @media screen and (min-width: 1366px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 0px;

    li {
      font-family: "quicksand";
      font-weight: 600;
      color: #bfbfbf;
      font-size: 18px;
    }
  }

  @media screen and (min-width: 1920px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 0px;

    li {
      font-family: "quicksand";
      font-weight: 600;
      color: #bfbfbf;
      font-size: 24px;
    }
  }

  @media screen and (min-width: 2730px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 0px;

    li {
      font-family: "quicksand";
      font-weight: 600;
      color: #bfbfbf;
      font-size: 30px;
    }
  }
`;

export const MainMenuList = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  li {
    padding: 0px 12px;
    font-family: "conditionregular";
    color: #bfbfbf;
    font-size: 20px;
  }
  @media screen and (min-width: 1200px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    li {
      padding: 0px 12px;
      font-family: "conditionregular";
      color: #bfbfbf;
      font-size: 20px;
    }
  }

  @media screen and (min-width: 1366px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    li {
      font-family: "conditionregular";
      color: #bfbfbf;
      font-size: 24px;
    }
  }

  @media screen and (min-width: 1920px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    li {
      font-family: "conditionregular";
      color: #bfbfbf;
      font-size: 30px;
    }
  }

  @media screen and (min-width: 2730px) {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    li {
      font-family: "conditionregular";
      color: #bfbfbf;
      font-size: 40px;
    }
  }
`;
