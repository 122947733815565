import React, { useEffect, useState } from 'react'
import LayoutDefault from '../layout/LayoutDefault'
import Slider from 'react-slick';
import PageLoading from '../components/utils/PageLoading';
import axe from '../config/axiosClient/axe';
import { SweetAlertDangerModal } from '../utils/alert';
import { BASE_FILE_URL } from '../config/urls';


function Home() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
  };

  const getProjects = async () => {
    try {
      setLoading(true)
      const response = await axe.get("/projects");
      if (response.status !== 200) {
        SweetAlertDangerModal("Something went wrong!");
      }
      if (response.status === 200) {
        setData(response.data.data)
        setLoading(false)

      }
    } catch (error) {
      SweetAlertDangerModal("Something went wrong!");
      setLoading(false)
    }
  }


  useEffect(() => {
    getProjects()
  }, [])

  if (loading) {
    return <PageLoading />
  }


  return (
    <LayoutDefault page="home" className="bg-black overflow-y-hidden" menuburgerColor='white-burger'>
      {<Slider {...slickSettings} className="h-full">
        {data.map((item, index) => (
          <div key={index} >
            <div
              style={{
                backgroundImage:
                  `url("${BASE_FILE_URL}/uploads${item.featured_image.path}")`,
              }}
              className="h-screen bg-cover bg-center bg-no-repeat flex items-end px-10 md:py-52 py-0"
            >
              <h3 className="font-quicksand font-bold flex flex-col text-white text-left md:pb-18 pb-24 md:pb-0 mb-7">
                <span className="text-3xl md:text-7xl">{item.title}</span>
                <span className="text-lg md:text-2xl font-normal">
                  {item.short_desc}
                </span>
              </h3>
            </div>
          </div>
        ))}
      </Slider>}
    </LayoutDefault >
  )
}

export default Home