import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';

import sec1DesktopLg from '../../assets/sustainability/images/section-1/desktop-lg.svg'
import sec1Desktop from '../../assets/sustainability/images/section-1/desktop.svg'
import sec1DesktopMd from '../../assets/sustainability/images/section-1/desktop-md.svg'
import sec1DesktopSm from '../../assets/sustainability/images/section-1/desktop-sm.svg'
import sec2DesktopLg from '../../assets/sustainability/images/section-2/desktop-lg.svg'
import sec2Desktop from '../../assets/sustainability/images/section-2/desktop.svg'
import sec2DesktopMd from '../../assets/sustainability/images/section-2/desktop-md.svg'
import sec2DesktopSm from '../../assets/sustainability/images/section-2/desktop-sm.svg'

import sec3DesktopLg from '../../assets/sustainability/images/section-3/desktop-lg.svg'
import sec3Desktop from '../../assets/sustainability/images/section-3/desktop.svg'
import sec3DesktopMd from '../../assets/sustainability/images/section-3/desktop-md.svg'
import sec3DesktopSm from '../../assets/sustainability/images/section-3/desktop-sm.svg'

import sec4DesktopLg from '../../assets/sustainability/images/section-4/desktop-lg.svg'
import sec4Desktop from '../../assets/sustainability/images/section-4/desktop.svg'
import sec4DesktopMd from '../../assets/sustainability/images/section-4/desktop-md.svg'
import sec4DesktopSm from '../../assets/sustainability/images/section-4/desktop-sm.svg'

import sec5DesktopLg from '../../assets/sustainability/images/section-5/desktop-lg.svg'
import sec5Desktop from '../../assets/sustainability/images/section-5/desktop.svg'
import sec5DesktopMd from '../../assets/sustainability/images/section-5/desktop-md.svg'
import sec5DesktopSm from '../../assets/sustainability/images/section-5/desktop-sm.svg'

import sec6DesktopLg from '../../assets/sustainability/images/section-6/desktop-lg.svg'
import sec6Desktop from '../../assets/sustainability/images/section-6/desktop.svg'
import sec6DesktopMd from '../../assets/sustainability/images/section-6/desktop-md.svg'
import sec6DesktopSm from '../../assets/sustainability/images/section-6/desktop-sm.svg'

import sec7DesktopLg from '../../assets/sustainability/images/section-7/desktop-lg.svg'
import sec7Desktop from '../../assets/sustainability/images/section-7/desktop.svg'
import sec7DesktopMd from '../../assets/sustainability/images/section-7/desktop-md.svg'
import sec7DesktopSm from '../../assets/sustainability/images/section-7/desktop-sm.svg'

import sec8DesktopLg from '../../assets/sustainability/images/section-8/desktop-lg.svg'
import sec8Desktop from '../../assets/sustainability/images/section-8/desktop.svg'
import sec8DesktopMd from '../../assets/sustainability/images/section-8/desktop-md.svg'
import sec8DesktopSm from '../../assets/sustainability/images/section-8/desktop-sm.svg'


import expImgMob1 from '../../assets/sustainability/images/section-1/mobile.svg'
import expImgMob2 from '../../assets/sustainability/images/section-2/mobile.svg'
import expImgMob3 from '../../assets/sustainability/images/section-3/mobile.svg'
import expImgMob4 from '../../assets/sustainability/images/section-4/mobile.svg'
import expImgMob5 from '../../assets/sustainability/images/section-5/mobile.svg'
import expImgMob6 from '../../assets/sustainability/images/section-6/mobile.svg'
import expImgMob7 from '../../assets/sustainability/images/section-7/mobile.svg'
import expImgMob8 from '../../assets/sustainability/images/section-8/mobile.svg'
import PageLoading from '../../components/utils/PageLoading';
import { ImageContainer, InfoContainerSectionEight, InfoContainerSectionFive, InfoContainerSectionFour, InfoContainerSectionNine, InfoContainerSectionSeven, InfoContainerSectionSix, InfoContainerSectionTen, InfoContainerSectionThree, InfoContainerSectionTwo, InitialContainer, SectionEight, SectionFive, SectionFour, SectionNine, SectionOne, SectionSeven, SectionSix, SectionTen, SectionThree, SectionTwo } from './Sustainability.styles';

function Sustainability() {
  const subMenuItems = [
    { title: "sustainability", href: "/expertise/sustainability" },
    { title: "energy-efficiency", href: "/expertise/energy-efficiency" },
    { title: "daylight", href: "/expertise/daylight" },
    { title: "acoustics", href: "/expertise/acoustics" },
    {
      title: "computational-simulation",
      href: "/expertise/computational-simulation",
    },
  ];

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }


  return (
    <LayoutDefault
      subMenuActiveItem="sustainability"
      page="expertise"
      subMenuItems={subMenuItems}
      className='overflow-y-auto'
      menuburgerColor='white-burger'
    >
      {/* Section 1 NEW */}
      <div className='bg-[#72665a]'>
        <InitialContainer className='bg-[#72665a] first-container'>
          <SectionTwo>
            <ImageContainer>
              <img src={expImgMob1} className='mobile' alt="" width={430} />
              <img src={sec1DesktopSm} className='desktop-sm' alt="" width={526} />
              <img src={sec1DesktopMd} className='desktop-md' alt="" width={526} />
              <img src={sec1Desktop} className='desktop' alt="" width={749} />
              <img src={sec1DesktopLg} className='desktop-lg' alt="" width={1305} />
            </ImageContainer>
            <InfoContainerSectionTwo>
              <h2>
                <span>Sustainability</span> topic is extensive...</h2>
            </InfoContainerSectionTwo>
          </SectionTwo>
        </InitialContainer>
        <div className="sticky inset-0 pointer-events-none z-50 -mt-[5%] hidden md:flex justify-end pr-24">
          <div className="md:block hidden w-12 h-24 caret bg-white opacity-60"></div>
        </div>
        <InitialContainer className='bg-[#72665a]'>
          <SectionTwo className='my-[200px]'>
            <ImageContainer>
              <img src={expImgMob2} className='mobile' alt="" width={430} />
              <img src={sec2DesktopSm} className='desktop-sm' alt="" width={782} />
              <img src={sec2DesktopMd} className='desktop-md' alt="" width={934} />
              <img src={sec2Desktop} className='desktop' alt="" width={1282} />
              <img src={sec2DesktopLg} className='desktop-lg' alt="" width={1908} />
            </ImageContainer>

          </SectionTwo>

        </InitialContainer>

        <InitialContainer className='bg-[#72665a]'>
          <SectionFour>
            <InfoContainerSectionFour>
              <p>
                <div />and can be defined in various ways. We consider it vital to tackle the significant challenges of <span>climate change</span> while prioritizing our communities’ welfare. This principle is a core aspect of our design activities. Our approach involves collaborating with our clients to create customized <span>design strategies</span> that benefit their businesses and the environment.
              </p>
              <p>
                <div />Our company has dedicated experts who specialize in conducting energy efficiency measures, <span>carbon impact</span> assessments, analyzing the outdoor thermal comfort environment, researching materials, designing landscapes and workplaces, and providing environmental and engineering services.
              </p>
            </InfoContainerSectionFour>
          </SectionFour>
        </InitialContainer>

        <InitialContainer className='bg-[#72665a]'>
          <SectionFour>
            <InfoContainerSectionFour>
              <p>
                <div /><span>Carbon impact:</span> Our team is leading efforts to meet the targets set by the Paris Agreement in terms of carbon impact. We have developed a comprehensive assessment process to overcome this challenge by thoroughly understanding our designs' whole-life carbon impact. We use a Sustainability Scientific approach to evaluate and analyse the results of all our projects. Our holistic approach is encapsulated in our <span>Sustainability Manifesto.</span>
              </p>
              <p>
                <div /><span>Environmental approach:</span> Our office leads a sustainable approach, evaluating our projects' environmental impact and creating forward-thinking solutions to reduce the effects of climate change. We prioritize resilience, adaptability, health, and well-being. We approach sustainability holistically in our designs, considering energy reduction, thermal comfort in building and urban scale, and daylight and indoor air quality. Our goal is to use design as a tool to improve the quality of life for everyone.
              </p>
              <p>
                <div />We strive to lead by example as an environmentally conscious office. To achieve this, we have created a customised pOint Responsibility Framework (PRF) to assess our office's sustainability performance, covering
              </p>
            </InfoContainerSectionFour>
          </SectionFour>
        </InitialContainer>

        <InitialContainer className='bg-[#562B7A]' style={{ 'padding': '0' }}>
          <SectionFive>
            <ImageContainer>
              <img src={expImgMob3} className='mobile' alt="" width={263} />
              <img src={sec3DesktopSm} className='desktop-sm' alt="" width={281} />
              <img src={sec3DesktopMd} className='desktop-md ms-10 mt-24' alt="" width={312} />
              <img src={sec3Desktop} className='desktop ms-16 mt-32' alt="" width={409} />
              <img src={sec3DesktopLg} className='desktop-lg ms-24 mt-48' alt="" width={677} />
            </ImageContainer>
            <InfoContainerSectionFive>
              <h2>
                Wellbeing
              </h2>
              <p>
                State of being comfortable, healthy, and happy..
              </p>
            </InfoContainerSectionFive>
          </SectionFive>
        </InitialContainer>

        <InitialContainer className='bg-[#B77129]' style={{ 'padding': '0' }}>
          <SectionSix>
            <ImageContainer>
              <img src={expImgMob4} className='mobile' alt="" width={380} />
              <img src={sec4DesktopSm} className='desktop-sm' alt="" width={420} />
              <img src={sec4DesktopMd} className='desktop-md ms-10 mt-24' alt="" width={480} />
              <img src={sec4Desktop} className='desktop ms-16 mt-32' alt="" width={680} />
              <img src={sec4DesktopLg} className='desktop-lg ms-24 mt-48' alt="" width={936} />
            </ImageContainer>
            <InfoContainerSectionSix>
              <h2>
                Community Impact
              </h2>
              <p>
                Examination of the influence of the project design on the social and economic aspects of its users and nearby communities to facilitate the project's smooth integration into the local community..
              </p>
            </InfoContainerSectionSix>
          </SectionSix>
        </InitialContainer>
        <InitialContainer className='bg-[#72665A]' style={{ 'padding': '0' }}>
          <SectionSeven>
            <ImageContainer>
              <img src={expImgMob5} className='mobile' alt="" width={430} />
              <img src={sec5DesktopSm} className='desktop-sm' alt="" width={480} />
              <img src={sec5DesktopMd} className='desktop-md ms-10 mt-24' alt="" width={547} />
              <img src={sec5Desktop} className='desktop ms-16 mt-32' alt="" width={752} />
              <img src={sec5DesktopLg} className='desktop-lg ms-24 mt-48' alt="" width={1222} />
            </ImageContainer>
            <InfoContainerSectionSeven>
              <h2>
                Energy and Carbon
              </h2>
              <p>
                Identifying ways to reduce energy consumption and waste, maximizing energy output, and minimizing the environmental impact of energy usage..
              </p>
            </InfoContainerSectionSeven>
          </SectionSeven>
        </InitialContainer>
        <InitialContainer className='bg-[#292560]' style={{ 'padding': '0' }}>
          <SectionEight>
            <ImageContainer>
              <img src={expImgMob8} className='mobile' alt="" width={430} />
              <img src={sec8DesktopSm} className='desktop-sm' alt="" width={465} />
              <img src={sec8DesktopMd} className='desktop-md ms-10 mt-24' alt="" width={530} />
              <img src={sec8Desktop} className='desktop ms-16 mt-32' alt="" width={728} />
              <img src={sec8DesktopLg} className='desktop-lg ms-24 mt-48' alt="" width={1096} />
            </ImageContainer>
            <InfoContainerSectionEight>
              <h2>
                Mobility and Connectivity
              </h2>
              <p>
                Prioritizes the use of modes of transportation that have minimal negative impacts on the environment, economy, and society. The goal is to reduce greenhouse gas emissions, improve air quality, promote public health, and support economic development while ensuring that transportation is safe, efficient, and affordable for everyone..
              </p>
            </InfoContainerSectionEight>
          </SectionEight>
        </InitialContainer>
        <InitialContainer className='bg-[#754D29]' style={{ 'padding': '0' }}>
          <SectionNine>
            <ImageContainer>
              <img src={expImgMob6} className='mobile' alt="" width={430} />
              <img src={sec6DesktopSm} className='desktop-sm' alt="" width={490} />
              <img src={sec6DesktopMd} className='desktop-md ms-10 mt-24' alt="" width={560} />
              <img src={sec6Desktop} className='desktop ms-16 mt-32' alt="" width={770} />
              <img src={sec6DesktopLg} className='desktop-lg ms-24 mt-48' alt="" width={1150} />
            </ImageContainer>
            <InfoContainerSectionNine>
              <h2>
                Land and Ecology
              </h2>
              <p>
                Understanding the relationships between different ecosystems, the impact of human activities on the environment, and the ways in which human societies can promote sustainable land use and ecological conservation..
              </p>
            </InfoContainerSectionNine>
          </SectionNine>
        </InitialContainer>
        <InitialContainer className='bg-[#A02631]' style={{ 'padding': '0' }}>
          <SectionTen>
            <ImageContainer>
              <img src={expImgMob7} className='mobile' alt="" width={250} />
              <img src={sec7DesktopSm} className='desktop-sm' alt="" width={260} />
              <img src={sec7DesktopMd} className='desktop-md ms-10 mt-24' alt="" width={290} />
              <img src={sec7Desktop} className='desktop ms-16 mt-32' alt="" width={406} />
              <img src={sec7DesktopLg} className='desktop-lg ms-24 mt-48' alt="" width={675} />
            </ImageContainer>
            <InfoContainerSectionTen>
              <h2>
                Social equity
              </h2>
              <p>
                Creating inclusive and equitable spaces, considering the diverse needs and perspectives of different groups and emphasizing the importance of collaboration and community engagement in the design process, to ensure that the design meets the evolving needs of the community it serves...
              </p>
            </InfoContainerSectionTen>
          </SectionTen>
        </InitialContainer>
      </div>


    </LayoutDefault>
  )
}

export default Sustainability