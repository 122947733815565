import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import innoImg1 from '../../assets/images/ideas/ideas_innovation.png'
import PageLoading from '../../components/utils/PageLoading'

function Innovation() {
  const subMenuItems = [
    { title: "publications", href: "/ideas/publications" },
    { title: "news", href: "/ideas/news" },
    { title: "research", href: "/ideas/research" },
    { title: "innovation", href: "/ideas/innovation" },
    { title: "explore", href: "/ideas/explore" }
  ];

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }

  return (
    <LayoutDefault subMenuActiveItem="innovation" page="ideas" className="overflow-y-scroll" subMenuItems={subMenuItems} menuburgerColor='white-burger'>
      <div className="h-full font-quicksand relative bg-[#6A7665] overflow-hidden pt-[150px] md:pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={innoImg1} className="w-full md:w-[50%]" />
            </div>

            <div className="w-full md:w-[120%] text-left mt-[30px] md:mt-0 md:text-right">
              <h2 className="text-white font-semibold text-[20px] md:text-3xl md:mb-5 mb-0 custom-justify block"><span className="md:text-5xl text-2xl text-[#3f463c]">pOint arkitekture</span> is constantly <br></br>innovating to find new solutions to ..</h2>

            </div>
          </div>
        </div>
      </div>
      <div className=" sticky inset-0 pointer-events-none z-50 -mt-32 hidden md:flex justify-end pr-32">
        <div className="md:block hidden w-12 h-32 caret7" ></div>
      </div>
      <div className="h-full font-quicksand relative bg-[#6a7765]  overflow-hidden pt-14 pb-14 md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 place-items-center">

            <div className="font-quicksand font-semibold text-left md:text-justify w-full md:w-[62%] text-white text-lg md:text-[20px] leading-[24px]">
              <p className="mb-5 ">
                <span className="text-xl md:text-2xl pl-[50px]">pOint arkitekture</span> is constantly innovating to find new solutions to design challenges and create more sustainable, efficient, and visually striking buildings.
              </p>
              <p className="mb-5">
                <span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]">Building Information Modelling (BIM):</span> Allowing the pOint arkitekture team to design, analyse, and optimise building systems, leading to more efficient and sustainable buildings.
              </p>
              <p className="mb-5">
                <span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]"> Net-Zero Energy Buildings:</span> Aiming to design buildings to generate as much energy as they consume, typically using renewable sources. This approach could significantly reduce energy costs and carbon emissions
              </p>
              <p className="mb-5"> <span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]"> Biophilic Design: </span>Seeking to integrate nature into building design, creating spaces that promote health and well-being. This includes green roofs, indoor gardens, and natural materials.
              </p>
              <p className="mb-5"><span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]">Passive Design:</span> Using natural means, such as sunlight and natural ventilation, to heat and cool buildings, reducing the need for energy-intensive mechanical systems.
              </p>
              <p> <span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]">Smart Building Technology:</span> Using sensors, automation, and artificial intelligence to create buildings that are more efficient, comfortable, and sustainable.
              </p>

            </div>
          </div>
        </div>
      </div>
      <div className="h-full font-quicksand relative bg-[#6a7765]  overflow-hidden md:pt-14 pt-0 pb-[100px] md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-rows-1 place-items-center">

            <div className="font-quicksand w-full md:w-[62%] text-white text-lg md:text-[20px] leading-[24px] font-semibold text-left md:text-justify">
              <p className="mb-5">
                <span className="text-xl md:text-2xl pl-[50px]">pOint arkitekture </span>is constantly innovating to find new solutions to design challenges and create more sustainable, efficient, and visually striking buildings.
              </p>
              <p className="mb-5">
                <span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]">Parametric Design: </span>Using computer algorithms to generate complex, highly customised building designs that respond to specific site conditions and client needs.
              </p>
              <p className="mb-5">
                <span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]">Augmented Reality: </span>Allowing the clients to view 3D models of buildings in real-world environments, helping the clients better understand how the building will look and function in its environment, leading to more informed design decisions.
              </p>
              <p>
                <span className="text-[#3f463c] text-xl md:text-2xl pl-[50px]"> Universal Design:</span> Designing buildings accessible and usable by people of all ages and abilities. This approach not only benefits people with disabilities but also benefits everyone, as it makes buildings and spaces more user-friendly, comfortable, and safe for everyone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault >
  )
}

export default Innovation