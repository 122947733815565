import axios from "axios";

import { BASE_API_URL } from "../urls";

const axe = axios.create();

axe.defaults.validateStatus = (status) => status < 500;

axe.defaults.baseURL = BASE_API_URL;

export default axe;
