import React from 'react'

function PageLoading() {
  return (
    <div className="container-loading">
      {/* <div className="lds-ripple"><div></div><div></div></div> */}
      <div className="circle-loading"></div>
    </div>
  )
}

export default PageLoading