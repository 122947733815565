import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import accouImg1Mobile from '../../assets/computal/section-1/mobile.svg'
import sec1DesktopLg from '../../assets/computal/section-1/desktop-lg.svg'
import sec1Desktop from '../../assets/computal/section-1/desktop.svg'
import sec1DesktopMd from '../../assets/computal/section-1/desktop-md.svg'
import sec1DesktopSm from '../../assets/computal/section-1/desktop-sm.svg'

import accouImg2Mobile from '../../assets/computal/section-2/mobile.svg'
import sec2DesktopLg from '../../assets/computal/section-2/desktop-lg.svg'
import sec2Desktop from '../../assets/computal/section-2/desktop.svg'
import sec2DesktopMd from '../../assets/computal/section-2/desktop-md.svg'
import sec2DesktopSm from '../../assets/computal/section-2/desktop-sm.svg'
import PageLoading from '../../components/utils/PageLoading';
import { ImageContainer, InfoContainerSectionOne, InfoContainerSectionThree, InfoContainerSectionTwo, InitialContainer, SectionOne, SectionThree, SectionTwo } from './Computal.styles';

function ComputalSimulation() {
  const subMenuItems = [
    { title: "sustainability", href: "/expertise/sustainability" },
    { title: "energy-efficiency", href: "/expertise/energy-efficiency" },
    { title: "daylight", href: "/expertise/daylight" },
    { title: "acoustics", href: "/expertise/acoustics" },
    {
      title: "computational-simulation",
      href: "/expertise/computational-simulation",
    },
  ];
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }


  return (
    <LayoutDefault subMenuActiveItem="computational-simulation" page="expertise" className='overflow-y-auto' subMenuItems={subMenuItems} menuburgerColor='white-burger'>
      <div className='bg-[#6C3A50]'>
        <InitialContainer className='bg-[#6C3A50] h-screen'>
          <SectionOne>
            <ImageContainer>
              <img src={accouImg1Mobile} className='mobile' alt="" width={376} />
              <img src={sec1DesktopSm} className='desktop-sm' alt="" width={576} />
              <img src={sec1DesktopMd} className='desktop-md' alt="" width={800} />
              <img src={sec1Desktop} className='desktop' alt="" width={1200} />
              <img src={sec1DesktopLg} className='desktop-lg' alt="" width={1620} />
            </ImageContainer>
            <InfoContainerSectionOne>
              <h2>
                Our team integrates <br className='hidden md:block' />
                <span> computational simulation</span> into the design workflow…
              </h2>
            </InfoContainerSectionOne>
          </SectionOne>
        </InitialContainer>

        <div className=" sticky inset-0 pointer-events-none z-50 -mt-[5%] hidden md:flex justify-end pr-32">
          <div className="md:block hidden w-12 h-24 caret4 bg-[#B1658C]"></div>
        </div>


        <InitialContainer className='bg-[#6C3A50]'>
          <SectionTwo>
            <ImageContainer>
              <img src={accouImg2Mobile} className='mobile' alt="" width={340} />
              <img src={sec2DesktopSm} className='desktop-sm' alt="" width={448} />
              <img src={sec2DesktopMd} className='desktop-md' alt="" width={514} />
              <img src={sec2Desktop} className='desktop' alt="" width={711} />
              <img src={sec2DesktopLg} className='desktop-lg' alt="" width={1011} />
            </ImageContainer>
            <InfoContainerSectionTwo>
              <p>
                <div />from early conceptual design to construction documentation. Typically, <span>dynamic simulation</span> software is used to model different aspects of a building’s design, such as energy performance, lighting, and acoustics. This allows our office to analyse the design from multiple perspectives and identify opportunities to optimise the building's performance, minimise <span>energy use</span>, and create comfortable indoor environments for occupants. Using computational simulation leads to more efficient, sustainable, and cost-effective designs and improves the overall quality of the built environment.
              </p>
              <p>
                <div />One area where computational simulation is commonly used in pOint arkitekture design office is in building energy and lighting performance analysis. With the increasing focus on <span>sustainable design</span>, the office is seeking ways to minimise energy consumption in buildings while providing comfortable indoor environments.
              </p>

            </InfoContainerSectionTwo>
          </SectionTwo>
        </InitialContainer>

        <InitialContainer className='bg-[#6C3A50] pt-[100px] pb-[100px]'>
          <SectionThree>
            <InfoContainerSectionThree>
              <p>
                <div />The team experts model the building's energy use, including the effects of heating, cooling, lighting, and ventilation systems. By analysing the <span>energy performance</span> of different design options, architects can identify ways to optimise a building's energy efficiency and reduce its environmental impact.
              </p>
              <p>
                <div />Computational simulation is also used in our office to analyse and advance building facades to evaluate the <span>thermal performance</span> under different conditions, such as temperature, humidity, and solar radiation. This allows the office to identify areas of improvement and design building envelopes that are more energy-efficient and comfortable for occupants.
              </p>

            </InfoContainerSectionThree>
          </SectionThree>
        </InitialContainer>
      </div>


      {/* <div className="h-auto md:h-screen relative bg-[#6C3B50] overflow-hidden">
        <div className='w-full md:w-[1200px] mx-auto md:my-auto'>
          <div className='h-auto md:h-screen relative'>
            <img
              className="hidden md:block w-full md:w-[100%]"
              src={comImg1}
            />
            <img
              className="block md:hidden w-auto mt-20"
              src={comImgMobile1}
            />
            <div className='absolute bottom-[-15%] right-4 md:left-auto md:right-[21%] md:bottom-[15%] w-[360px] md:w-[600px] md:ms-auto'>
              <p className='font-quicksand font-regular text-[18px] leading-[30px] tracking-[-0.5px] text-[#ffffff] mb-0 text-end'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our team integrates <span className='text-[#B1658C] font-medium text-[30px]'>computational simulation</span> into the design workflow…
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" sticky inset-0 pointer-events-none z-50 -mt-32 hidden md:flex justify-end pr-32">
        <div className="md:block hidden w-12 h-32 caret4 bg-[#B1658C]"></div>
      </div>

      <div className="h-full relative bg-[#6C3B50] overflow-hidden">
        <div className='w-full md:w-[1200px] mx-auto my-auto'>
          <div className='h-auto md:h-screen relative'>
            <img
              className="hidden md:block w-full md:w-[100%]"
              src={comImg2}
            />
            <img
              className="block md:hidden w-auto mt-10 mb-[600px]"
              src={comImgMobile2}
            />
            <div className='absolute right-4 ms-0 top-[400px] md:right-[21%] md:top-[5%] w-[370px] md:w-[400px] md:ms-auto'>
              <p className='font-quicksand font-regular text-[18px] leading-[18px] text-[#ffffff] mb-[20px] text-justify'>
                &nbsp;&nbsp;&nbsp;from early conceptual design to construction documentation. Typically, <span className='text-[#B1658C] font-medium text-[25px]'>dynamic simulation</span> software is used to model different aspects of a building’s design, such as energy performance, lighting, and acoustics. This allows our office to analyse the design from multiple perspectives and identify opportunities to optimise the building's performance, minimise <span className='text-[#B1658C] font-medium text-[25px]'>energy use</span>, and create comfortable indoor environments for occupants. Using computational simulation leads to more efficient, sustainable, and cost-effective designs and improves the overall quality of the built environment.
              </p>
              <p className='font-quicksand font-regular text-[18px] leading-[18px] text-[#ffffff] mb-[20px] text-justify'>
                &nbsp;&nbsp;&nbsp;&nbsp;One area where computational simulation is commonly used in pOint arkitekture design office is in building energy and lighting performance analysis. With the increasing focus on <span className='text-[#B1658C] font-medium text-[25px]'>sustainable design</span>, the office is seeking ways to minimise energy consumption in buildings while providing comfortable indoor environments.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </LayoutDefault >
  )
}

export default ComputalSimulation