import styled from "styled-components";

export const ProfileContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15%;
  padding-bottom: 10%;

  @media screen and (min-width: 768px) {
    display: none;
  }

  /* @media screen and (min-width: 768px) {
    height: 100vh;
    padding-top: 5%;
    width: 95%;
    display: none;
  }

  @media screen and (max-width: 1200px) {
    height: 100vh;
    padding-top: 5%;
    width: 95%;
  }

  @media screen and (max-width: 1366px) {
    padding-top: 5%;
    width: 95%;
  }

  @media screen and (max-width: 1920px) {
    padding-top: 5%;
    width: 95%;
  }

  @media screen and (min-width: 2730px) {
    padding-top: 5%;
    width: 95%;
  } */
`;

export const ProfileFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  position: relative;
  flex-direction: column;
  padding-top: 20%;
  padding-bottom: 10%;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    width: 80%;
    justify-content: center;
  }

  @media screen and (min-width: 1366px) {
    flex-direction: row;
    width: 80%;
    justify-content: center;
  }

  @media screen and (min-width: 1920px) {
    flex-direction: row;
    width: 80%;
    justify-content: center;
  }

  @media screen and (min-width: 2730px) {
    flex-direction: row;
    width: 80%;
    justify-content: center;
  }
`;

export const ColOne = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  flex-direction: column;

  p {
    font-family: "quicksand";
    font-size: 18px;
    font-weight: 400;

    span {
      font-weight: 600;
      font-size: 20px;
    }
  }

  @media screen and (min-width: 1200px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 2730px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 26px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 30px;
      }
    }
  }
`;

export const ColTwo = styled.div`
  display: flex;
  align-items: flex-start;

  justify-content: space-between;
  gap: 30px;
  flex-direction: column;

  span.point-o {
    width: 30px;
    height: 30px;
    background: #000;
    display: inline-block;
    border-radius: 50%;
  }

  p {
    font-family: "quicksand";
    font-size: 18px;
    font-weight: 400;
    max-width: 500px;

    span {
      font-weight: 600;
      font-size: 20px;
    }
  }

  @media screen and (min-width: 1200px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 16px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  @media screen and (min-width: 2730px) {
    gap: 20px;
    p {
      font-family: "quicksand";
      font-size: 26px;
      font-weight: 400;

      span {
        font-weight: 600;
        font-size: 30px;
      }
    }
  }
`;

export const Point = styled.span`
  width: 30px;
  height: 30px;
  background: #000;
  display: inline-block;
  border-radius: 50%;

  img {
    position: absolute;
    top: -135px;
    left: -37%;
    z-index: 9999;
    width: 300px;
    height: 300px;
  }

  @media screen and (min-width: 1200px) {
    img {
      position: absolute;
      top: calc(50% - 412px);
      left: -225px;
      z-index: 9999;
      width: 500px;
      height: 500px;
    }
  }

  @media screen and (min-width: 1366px) {
  }

  @media screen and (min-width: 1920px) {
  }

  @media screen and (min-width: 2730px) {
  }
`;

export const ImageContainer = styled.div`
  margin-top: 25%;
  .mobile {
    display: block;
  }

  .desktop-sm {
    display: none;
  }
  .desktop-md {
    display: none;
  }
  .desktop {
    display: none;
  }
  .desktop-lg {
    display: none;
  }
  @media screen and (min-width: 768px) {
    margin-top: 0%;
    .mobile {
      display: none;
    }
    .desktop-sm {
      display: block;
    }
    .desktop-md {
      display: none;
    }
    .desktop {
      display: none;
    }
    .desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 1366px) {
    margin-top: 0%;
    .mobile {
      display: none;
    }
    .desktop-sm {
      display: none;
    }
    .desktop-md {
      display: block;
    }
    .desktop {
      display: none;
    }
    .desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 1920px) {
    margin-top: 0%;
    .mobile {
      display: none;
    }
    .desktop-sm {
      display: none;
    }
    .desktop-md {
      display: none;
    }
    .desktop {
      display: block;
    }
    .desktop-lg {
      display: none;
    }
  }

  @media screen and (min-width: 2730px) {
    margin-top: 0%;
    .mobile {
      display: none;
    }
    .desktop-sm {
      display: none;
    }
    .desktop-md {
      display: none;
    }
    .desktop {
      display: none;
    }
    .desktop-lg {
      display: block;
    }
  }
`;
