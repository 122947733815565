import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layout/LayoutDefault';
import expImg1 from '../../assets/images/ideas/ideas_explore.png'
import PageLoading from '../../components/utils/PageLoading'

function Explore() {
  const subMenuItems = [
    { title: "publications", href: "/ideas/publications" },
    { title: "news", href: "/ideas/news" },
    { title: "research", href: "/ideas/research" },
    { title: "innovation", href: "/ideas/innovation" },
    { title: "explore", href: "/ideas/explore" }
  ];

  const [loading, setLoading] = useState(true)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }, [])

  if (loading) {
    return <PageLoading />
  }

  return (
    <LayoutDefault subMenuActiveItem="explore" page="ideas" className="overflow-y-scroll" subMenuItems={subMenuItems} menuburgerColor='white-burger'>
      <div className="h-full font-quicksand relative bg-[#8F95A6] overflow-hidden  pb-14 pt-[180px] md:pt-14  md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-cols-1 md:grid-cols-2 pl-0 pr-0 md:pl-10 md:pr-40 place-items-end">
            <div className="w-full">
              <img src={expImg1} className="w-full md:w-[55%] " />
            </div>
            <div className="w-full md:w-[120%] text-left md:text-right mt-[30px] md:mt-0">
              <h2 className="text-white md:text-4xl font-semibold text-[20px]">
                the firm's design services and <br></br>approach to areas of expertise..
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className=" sticky inset-0 pointer-events-none z-50 -mt-32 hidden md:flex justify-end pr-32">
        <div className="md:block hidden w-12 h-32 caret8" ></div>
      </div>
      <div className="h-full font-quicksand relative bg-[#8f95a6]  overflow-hidden pt-14 pb-[100px] md:pb-14 md:pt-14  md:pl-0 md:pr-0 pl-[30px] pr-[30px]">
        <div className="container mx-auto h-full">
          <div className="h-full grid grid-rows-1 place-items-center">

            <div className=" text-white">
              <ul className="list-disc md:text-[22px] leading-[25px] font-semibold text-[20px] ml-[30px] md:ml-0">
                <li className="pb-3"> Conceptual Design and Sketching</li>
                <li className="pb-3">Schematic Design and Space Planning</li>
                <li className="pb-3">Design Development and Detailing</li>
                <li className="pb-3">Construction Documentation and Drafting</li>
                <li className="pb-3">Permitting and Regulatory Compliance</li>
                <li className="pb-3">Building Information Modelling(BIM) and 3D Modelling</li>
                <li className="pb-3">Project Management and Coordination</li>
                <li className="pb-3">Sustainable Design Consulting</li>
                <li className="pb-3">Building Envelope Consulting and Detailing</li>
                <li className="pb-3">Energy, Acoustical and Lighting Design</li>
                <li className="pb-3 text-[#6d7489]">Interior design and Space Planning</li>
                <li className="pb-3 text-[#6d7489]">Landscape Design and Site Planning</li>
                <li className="text-[#6d7489]">Cost Estimating and Budget Analysis</li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault >
  )
}

export default Explore