import styled from "styled-components";

export const Paragraph = styled.p`
  margin-left: auto;
  @media screen and (min-width: 1200px) {
    width: 330px;
    color: #000;
    text-align: right;
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
  }

  @media screen and (min-width: 1366px) {
    width: 440px;
    color: #000;
    text-align: right;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 30px; /* 142.782% */
  }

  @media screen and (min-width: 1920px) {
    width: 500px;
    color: #000;
    text-align: right;
    font-family: Quicksand;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 34px; /* 152.991% */
  }

  @media screen and (min-width: 2730px) {
    width: 860px;
    color: #000;
    text-align: right;
    font-family: Quicksand;
    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    line-height: 36px; /* 153.054% */
  }
`;
